import { CardHeader, Text, useColorMode } from "@chakra-ui/react";
import { useMemo } from "react";
import { DataToSet } from ".."
import MyCard from "../../../../../Components/micro/Card";
import EquipmentBreakdown from "./EquipmentBreakdown";

const EquipmentTotal = ({
    data
}: {
    data: DataToSet
}) => {
    const { colorMode } = useColorMode();
    const { border } = useMemo(() => ({
        border: `${colorMode}.border`
    }), [colorMode]);

    return <MyCard p={0}>
        <CardHeader borderBottom={"1px solid"} borderColor={border} display="flex" alignItems="center" justifyContent={"space-between"} px={2} pb={1} pt={3}>
            <Text fontWeight={500} fontSize="lg">Equipment Total</Text>
        </CardHeader>
        <EquipmentBreakdown equipments={data.equipments} />
    </MyCard>
}

export default EquipmentTotal