import { DataToSet } from ".";
import { get } from "../../../Components/firebase/api/db";
import store from "../../../Components/Store";
import {
  dateAtom,
  machinesAtom,
  shiftsAtom,
  uidAtom,
} from "../../../Components/Store/atoms";
import dayjs from "../../../Components/Functions/dayjs";
import secondsToHourMin from "../../../Components/Functions/converters/secondsToHourMin";
import { MachineStatusType } from "../../Machines/types";
import {
  orderByChild,
  query,
  ref,
  startAt,
  get as firebaseGet,
  limitToLast,
} from "firebase/database";
import { db } from "../../../Components/firebase";
import { downtimeCategoriesType } from "./Downtime/categories";

const fetch = async (machineID: string): Promise<DataToSet | "NOT_FOUND"> => {
  const machinesAllowed = store.get(machinesAtom) || {};
  const date = store.get(dateAtom);
  const shifts = store.get(shiftsAtom);
  if (machinesAllowed[machineID] === undefined || !shifts || !date)
    return "NOT_FOUND";
  const machinesSnap = await get(`machines/${machineID}`);
  const machine = machinesSnap.val() || { unit: 1 };
  const moldName = machine.installedMold
    ? machine.installedMold.name
    : machine.mold_name;
  const promises = [];
  const uid = store.get(uidAtom);
  promises.push(get(`units/${machine.unit}`));
  promises.push(get(`production-targets/active/${machineID}`));
  promises.push(get(`molds/${machineID}/${moldName}/cycleTimeAvg`));
  promises.push(
    firebaseGet(
      query(
        ref(
          db,
          `users/${uid}/production-targets/completed/${machineID}/${moldName}`,
        ),
        limitToLast(1),
      ),
    ),
  );
  promises.push(get(`machines/${machineID}/monitor-downtime`));
  promises.push(get("device-categories"));
  const [
    unitSnap,
    productionTargetSnap,
    cycleTimeSnap,
    latestTargetReportSnap,
    monitorDowntimeSnap,
    deviceCategorySnap,
  ] = await Promise.all(promises);
  const unit = unitSnap.val();
  const deviceCategories = deviceCategorySnap.val() || {};
  const monitorDowntime = !!monitorDowntimeSnap.val();
  const latestTargetReport = Object.entries(latestTargetReportSnap.val() || {});
  const completedTarget: DataToSet["lastCompletedTarget"] =
    latestTargetReport.length === 0
      ? null
      : {
          id: latestTargetReport[0][0],
          name: (latestTargetReport[0][1] as any).name,
          target: (latestTargetReport[0][1] as any).target.total,
          completed: (latestTargetReport[0][1] as any).completedAt,
          started: (latestTargetReport[0][1] as any).startedAt,
          efficiency: (latestTargetReport[0][1] as any).efficiency * 100 || 0,
          hours: [],
        };
  if (completedTarget) {
    for (const [dateHour, _data] of Object.entries(
      (latestTargetReport[0][1] as any)?.hours || {},
    )) {
      const data = _data as any;
      const dateHourSplit = (dateHour as string).split("-"); // 2024-01-01-01
      const date = dateHourSplit.slice(0, 3).join("-");
      const efficiency =
        data.ontime && data.ontime + data.downtime
          ? (data.ontime / (data.ontime + data.downtime)) * 100
          : 0;
      completedTarget.hours.push({
        efficiency: efficiency,
        production: data.production || 0,
        time: data.time || "NA",
        date: dayjs(date).format("D MMM, YY"),
      });
    }
  }
  const productionTargets = productionTargetSnap.val() || {};
  const cycleTime = cycleTimeSnap.val() || 0;
  const {
    cleanDisconnect,
    last_seen,
    lastContact,
  }: {
    connected: boolean;
    cleanDisconnect: boolean;
    last_seen: number;
    lastContact: number;
  } = unit as any;
  const diff = dayjs().diff(dayjs.unix(lastContact || 0), "minute");
  const connected = diff < 1;
  const rn = dayjs();
  const rnSecs = rn.hour() * 3600 + rn.minute() * 60 + rn.second();
  const operator = (() => {
    let operator = "A - " + machine.operator_a;
    if (isNaN(shifts.B)) operator = "A - " + machine.operator_a;
    else if (rnSecs > shifts.A && rnSecs < shifts.B)
      operator = "A - " + machine.operator_a;
    else if (isNaN(shifts.C)) operator = "B - " + machine.operator_b;
    else if (rnSecs > shifts.B && rnSecs < shifts.C)
      operator = "B - " + machine.operator_b;
    else if (rnSecs > shifts.C && rnSecs < shifts.A)
      operator = "C - " + machine.operator_c;
    return operator;
  })();
  const shiftTiming = (() => {
    const shift = operator.split(" - ")[0];
    if (shift === "A") {
      if (isNaN(shifts.B))
        return secondsToHourMin(shifts.A) + " - " + secondsToHourMin(shifts.A);
      else
        return secondsToHourMin(shifts.A) + " - " + secondsToHourMin(shifts.B);
    } else if (shift === "B") {
      if (isNaN(shifts.C))
        return secondsToHourMin(shifts.B) + " - " + secondsToHourMin(shifts.A);
      else
        return secondsToHourMin(shifts.B) + " - " + secondsToHourMin(shifts.C);
    } else if (shift === "C")
      return secondsToHourMin(shifts.C) + " - " + secondsToHourMin(shifts.A);
    else return "NA";
  })();
  const status = (() => {
    if (connected) {
      if (machine.machine_status) return "ON";
      else return "IDLE";
    } else {
      if (cleanDisconnect) return "OFF";
      else return "NA";
    }
  })() as MachineStatusType;
  machine.at = status === "NA" ? lastContact || last_seen : machine.at;
  const statusSince = (() => {
    if (isNaN(machine.at)) return dayjs(machine.at).unix();
    else return +machine.at;
  })();
  const updated = (() => {
    if (isNaN(machine.updated)) return dayjs(machine.updated).unix();
    else return +machine.updated;
  })();
  const started = Math.max(+(
    (productionTargets?.[moldName]?.startedAt || 0) / 1000
  ).toFixed(0) - 60, 0);
  let logs: DataToSet["timing"]["downtime"]["logs"] = [];
  let scheduledDowntime = 0;
  if (started) {
    const downtimeSnap = await firebaseGet(
      query(
        ref(db, `users/${uid}/downtime/${machineID}`),
        orderByChild("start"),
        startAt(started),
      ),
    );
    const downtime = (downtimeSnap.val() || {}) as {
      [id: string]: {
        start: number;
        end: number | undefined;
        reason:
          | string
          | {
              category: downtimeCategoriesType;
              note: string;
              scheduled: boolean;
            };
        status: "IDLE" | "OFF";
      };
    };
    for (const [id, { start, end, reason }] of Object.entries(downtime)) {
      const myReason: {
        category: downtimeCategoriesType;
        note: string;
        scheduled: boolean;
      } =
        typeof reason === "string"
          ? {
              category: "uncategorized",
              note: reason,
              scheduled: false,
            }
          : reason;
          if (myReason?.scheduled) scheduledDowntime += end ? end - start : 0;
      logs.push({
        start,
        end: end || 0,
        reason: myReason,
        id,
      });
    }
  }
  if (logs.length === 0) logs = "NOT_FOUND";
  const projectedEndTime = (() => {
    const shots =
      ((productionTargets?.[moldName]?.target?.total || 0) -
        (productionTargets?.[moldName]?.target?.current || 0)) /
      (machine.installedMold
        ? +machine.installedMold.cavities
        : +machine.cavities);
    const time = cycleTime * Math.ceil(shots);
    if (time === 0) return 0;
    return dayjs().unix() + time;
  })();
  const hours: DataToSet["hours"] = [];
  const timings = {
    ontime: 0,
    downtime: 0,
  };
  for (const [dateHour, _data] of Object.entries(
    productionTargets?.[moldName]?.hours || {},
  )) {
    const data = _data as any;
    const dateHourSplit = (dateHour as string).split("-"); // 2024-01-01-01
    const date = dateHourSplit.slice(0, 3).join("-");
    timings.ontime += data.ontime || 0;
    timings.downtime += data.downtime || 0;
    hours.push({
      downtime: data.downtime || 0,
      ontime: data.ontime || 0,
      production: data.production || 0,
      time: data.time || "NA",
      date: dayjs(date).format("D MMM, YY"),
    });
  }
  const due = productionTargets?.[moldName]?.target?.due;
  const track: number = (() => {
    if (!due) return 0;
    const dueTime = dayjs(due).unix();
    const diff = dueTime - projectedEndTime;
    return diff;
  })();
  const shouldbe: number = (() => {
    let pcs = productionTargets?.[moldName]?.target?.current || 0;
    if (!track || !due) return pcs;
    if (track > 0 || !cycleTime) return pcs;
    if (dayjs(due).isBefore(dayjs()))
      return productionTargets?.[moldName]?.target?.total || 0;
    pcs += Math.abs(track) / cycleTime;
    return +pcs.toFixed(0);
  })();
  const data: DataToSet = {
    notificationCategories: Object.keys(deviceCategories) as string[],
    name: machinesAllowed[machineID],
    status: {
      status,
      since: statusSince,
    },
    target: {
      defaultMonitorDowntime: monitorDowntime,
      machineMonitorDowntime: !!productionTargets?.[moldName]?.target?.monitorDowntime,
      name: productionTargets?.[moldName]?.name || "",
      due: productionTargets?.[moldName]?.target?.due,
      target: productionTargets?.[moldName]?.target?.total || 0,
      hourlyTarget: productionTargets?.[moldName]?.target?.hourlyTarget || 0,
      notifications:
        productionTargets?.[moldName]?.notifications ||
        ([] as DataToSet["target"]["notifications"]),
      notificationCategories:
        productionTargets?.[moldName]?.notificationCategories || [],
      started,
    },
    progress: {
      total: productionTargets?.[moldName]?.target?.total || 0,
      current: productionTargets?.[moldName]?.target?.current || 0,
      track,
      shouldbe,
    },
    mold: {
      name: moldName,
      since: machine.mold_start_time,
      cavities: machine.installedMold
        ? +machine.installedMold.cavities
        : +machine.cavities,
      cycleTime,
    },
    lastCompletedTarget: completedTarget,
    hours,
    OEE: +(
      // ratio between ontime and ontime + idle time
      (
        timings.ontime && timings.ontime + timings.downtime
          ? (timings.ontime / (timings.ontime + timings.downtime - scheduledDowntime)) * 100
          : 0
      ).toFixed(2)
    ),
    timing: {
      ontime: timings.ontime || 0,
      downtime: {
        total: timings.downtime || 0,
        logs,
      },
      projectedEndTime,
    },
    operator: {
      name: operator,
      shift: shiftTiming,
    },
    updated,
  };
  // console.log("🚀 ~ fetch ~ data:", data);
  return data;
};

export default fetch;
