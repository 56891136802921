import { SimpleGrid } from "@chakra-ui/react";
import { DataToSet } from "..";
import IsScreenSmall from "../../../../Components/Store/hooks/IsScreenSmall";
import OEE from "./OEE";
import ProgressSection from "./ProgressSection";
import ProjectedCompletion from "./ProjectedCompletion";

const CurrentStats = ({
    data
}: {
    data: DataToSet
}) => {
    const isSmall = IsScreenSmall(1200);

    return <SimpleGrid alignItems={"center"} columns={isSmall ? 1 : 3} gap={isSmall ? 5 : 3}>
        {isSmall ? <>
            <ProgressSection data={data.progress} status={data.status} />
            <OEE data={data.OEE} hours={data.hours} />
            <ProjectedCompletion data={data} />
        </>
            : <>
                <OEE data={data.OEE} hours={data.hours} />
                <ProgressSection data={data.progress} status={data.status} />
                <ProjectedCompletion data={data} />
            </>}
    </SimpleGrid>
}

export default CurrentStats