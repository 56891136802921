import { GridItem, SimpleGrid } from "@chakra-ui/react"
import { MachineStats } from "../.."
import MachineTable from "./MachineTable"
import PieChart from "./PieChart"

const MachineBreakdown = ({
    machines,
}: {
    machines: {
        [key: string]: MachineStats
    },
}) => {
    return <SimpleGrid
        columns={{
            base: 1,
            md: 5,
        }}>
        <MachineTable machines={machines} />
        <GridItem colSpan={{
            base: 1,
            md: 2
        }}>
            <PieChart machines={machines} />
        </GridItem>
    </SimpleGrid>
}

export default MachineBreakdown