import { Box, Center, CircularProgress, CircularProgressLabel, Text, useColorMode, VStack } from "@chakra-ui/react"
import { DataToSet } from ".."
import MyTooltip from "../../../../Components/micro/MyTooltip"
import { useMemo } from "react"
import dayjs from "../../../../Components/Functions/dayjs"
import IsScreenSmall from "../../../../Components/Store/hooks/IsScreenSmall"


const ProjectedCompletion = ({
    data
}: {
    data: DataToSet
}) => {
    const { colorMode } = useColorMode();
    const { subText } = useMemo(() => ({
        subText: `${colorMode}.subText`,
    }), [colorMode]);
    const isBelow1400 = IsScreenSmall(1400);
    const isBelow1200 = IsScreenSmall(1200);

    const efficiency = useMemo(() => {
        if (data.progress.total === 0) return 0;
        if (data.target.due === null) return 100;
        const start = data.target.started;
        const now = dayjs().unix() - start;
        const dueTime = data.target.due === null ? dayjs(data.target.due).unix() : data.timing.projectedEndTime - start;
        // if (now > dueTime) return 0;
        return (now / dueTime) * 100;
    }, [data]);


    return <Box>
        <Text
            textAlign={isBelow1200 ? "left" : "right"}
            fontSize="xl"
            textTransform={"uppercase"}
            opacity={0.8}
            fontWeight={700}>Completion Progress</Text>
        <Center>
            <CircularProgress
                thickness={"5px"}
                rounded={"full"}
                size={
                    isBelow1400 ? "300px"
                        : "350px"
                }
                value={efficiency}
                color={data.progress.track > 0 ? "green" : data.progress.track < -1800 ? "red" : "orange"}
                trackColor={"#cccccc40"}
                capIsRound>
                <CircularProgressLabel>
                    <VStack>
                        <Text fontSize={"md"} fontWeight={600}>
                            {data.target.started ? <MyTooltip label={dayjs.unix(data.target.started).format("Do MMM HH:mm A")}>
                                {dayjs.unix(data.target.started).fromNow()}
                            </MyTooltip> : "Not Started Yet"}
                            <Text color={subText} textTransform="uppercase" fontSize="sm">Started</Text>
                        </Text>
                        <Text fontSize={"2xl"} fontWeight={600}>
                            <MyTooltip label={data.timing.projectedEndTime ? dayjs.unix(data.timing.projectedEndTime).format("Do MMM HH:mm A") : "Never"}>
                                {data.timing.projectedEndTime ? dayjs.unix(data.timing.projectedEndTime).fromNow() : "Never"}
                            </MyTooltip>
                            <Text color={subText} textTransform="uppercase" fontSize="sm">Expected End</Text>
                        </Text>
                        <Text fontSize={"md"} fontWeight={600}>
                            {data.target.due ? <MyTooltip label={dayjs(data.target.due).format("Do MMM HH:mm A")}>
                                {dayjs(data.target.due).fromNow()}
                            </MyTooltip> : "No Due Set"}
                            <Text color={subText} textTransform="uppercase" fontSize="sm">Due Set</Text>
                        </Text>
                    </VStack>
                </CircularProgressLabel>
            </CircularProgress>
        </Center>
    </Box>
}

export default ProjectedCompletion