import { Area, AreaConfig } from "@ant-design/plots";
import { HStack, Progress, Text, useColorMode, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { DataToSet } from "..";
import IsScreenSmall from "../../../../Components/Store/hooks/IsScreenSmall";
import { viewAll } from "../../../Reports/Viewer/FactoryReport/Machine/MachineBreakdown/PieChart";


const OEE = ({
    data,
    hours
}: {
    data: DataToSet["OEE"],
    hours: DataToSet["hours"]
}) => {
    const { colorMode } = useColorMode();
    const isBelow1200 = IsScreenSmall(1200);
    const isBelow600 = IsScreenSmall(600);

    const { subText } = useMemo(() => ({
        subText: `${colorMode}.subText`,
    }), [colorMode]);
    const hourlyData: {
        time: string,
        value: number,
    }[] = useMemo(() => {
        const _data: {
            time: string,
            value: number,
        }[] = [];
        for (const key in hours) {
            const hour = hours[key];
            _data.push({
                time: `${hour.date}|${hour.time}`,
                value: hour.production,
            });
        }
        return _data;
    }, [hours]);

    const color = useMemo(() => data >= 75 ? "#0d9f8b" :
        data >= 50 ? "#FDD36A" :
            "#e95e44", [data]);

    const config: AreaConfig = {
        data: hourlyData,
        xField: 'time',
        yField: 'value',
        xAxis: {
            label: null,
            range: [0, 1],
        },
        yAxis: {
            label: null,
            tickCount: 0
        },
        height: isBelow1200 ? 200 : 100,
        width: isBelow1200 ? isBelow600 ? 300 : undefined : 300,
        label: false,
        animation: false,
        smooth: true,
        legend: false,
        line: {
            color,
            style: {
                lineWidth: 2,
            }
        },
        areaStyle: {
            fill: `l(270) 0:${color}00 1:${color}60`,
        },
        tooltip: {
            formatter: datum => {
                return {
                    name: datum.time.split("|")[1],
                    value: viewAll(datum.value, "production")
                }
            },
            title: v => v.split("|")[0]
        }
    };

    return <VStack justifyContent={"space-between"} alignItems="flex-start" h="90%" pb={10} w="100%">
        <Text
            textAlign="left"
            fontSize="xl"
            textTransform={"uppercase"}
            opacity={0.8}
            fontWeight={700}>Performance</Text>
        <VStack gap={3} minH="250px" justifyContent={"flex-end"} alignItems="center" w="100%">
            <Area {...config} />
            <HStack w="100%" justifyContent={"center"} alignItems="center">
                <Text fontSize="sm" color={subText} fontWeight={700}>OEE: </Text>
                <Progress w="70%" borderRightRadius={"md"} size="lg" value={data} colorScheme={
                    data >= 75 ? "green" :
                        data >= 50 ? "yellow" :
                            "red"
                } />
                <Text fontSize="sm" color={subText} fontWeight={700}>{data}%</Text>
            </HStack>
        </VStack>
    </VStack>
}

export default OEE