import { CardBody, CardFooter, CardHeader, Center, GridItem, SimpleGrid, Skeleton, SkeletonCircle, useColorMode, VStack } from "@chakra-ui/react"
import { useMemo } from "react";
import MyCard from "../../../Components/micro/Card"

const LoadingCard = () => {
  const { colorMode } = useColorMode();
  const { border } = useMemo(() => ({
    border: `${colorMode}.border`
  }), [colorMode]);

  return <MyCard p={0}>
    <CardHeader
      h="70px"
      borderTopRadius={"md"}
      borderBottom={`1px solid`}
      borderBottomColor={border}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      py={3}>
      <VStack gap={1} w="100%">
        <Skeleton h="20px" w="50%" />
        <Skeleton h="10px" w="30%" />
      </VStack>
    </CardHeader>
    <CardBody
      p={0}
      as={VStack}>
      <Center flexDir={"column"} mt={3}>
        <SkeletonCircle size={"250px"} />
        <Skeleton h="10px" w="50%" mt={3} px={2} />
      </Center>
      <SimpleGrid columns={2} w="100%" justifyContent={"flex-start"} gap={2}>
        <GridItem colSpan={2} w="100%" borderY={"1px solid"} borderColor={border} py={2}>
          <Skeleton h="20px" w="100%" mt={3} />
        </GridItem>
        <GridItem colSpan={2} w="100%" borderY={"1px solid"} borderColor={border} py={2}>
        </GridItem>
        <Skeleton h="10px" w="100%" mt={3} />
        <Skeleton h="10px" w="100%" mt={3} />
        <GridItem colSpan={2} w="100%" borderY={"1px solid"} borderColor={border} p={2}>
          <Skeleton h="10px" w="100%" mt={3} />
        </GridItem>
      </SimpleGrid>
    </CardBody>
    <CardFooter
      borderTop={`1px solid`}
      borderTopColor={border}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      px={4}>
      <Skeleton h="10px" w="30%" />
      <Skeleton h="10px" w="30%" />
    </CardFooter>
  </MyCard>
}

export default LoadingCard