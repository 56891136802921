import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Icon,
  Link as ChakraLink,
  useColorMode,
  HStack,
} from '@chakra-ui/react'
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import NAV_ITEMS from './navItems';
import { Link, useLocation } from "react-router-dom";
import { IconType } from 'react-icons';
import { useMemo } from 'react';
import FactorySelector from './FactorySelector';


const DesktopNav = () => {
  const { pathname } = useLocation();
  const { colorMode } = useColorMode();
  const { linkColor, linkHoverColor, popoverContentBgColor } = useMemo(() => {
    return {
      linkColor: `${colorMode}.navText`,
      linkHoverColor: `primary`,
      popoverContentBgColor: `${colorMode}.popoverContentBgColor`
    }
  }, [colorMode]);
  const activeColor = "primary";

  return <HStack justify={"space-between"} w="100%">
    <Stack direction={'row'} spacing={4} alignItems={"center"}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <ChakraLink
                as={Link}
                display={'block'}
                p={2}
                to={navItem.to}
                fontSize={'sm'}
                fontWeight={500}
                color={pathname === navItem.to ? activeColor : linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor
                }}>
                <Flex
                  transition={'all .3s ease'}
                  justify={'space-between'}
                  gap={1}
                  align={'center'}>
                  <Icon w={4} h={4} as={navItem.icon} />
                  {navItem.label}
                  {navItem.subElements && <Icon w={5} h={5} as={BiChevronDown} />}
                </Flex>
              </ChakraLink>
            </PopoverTrigger>
            {navItem.subElements && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={2}
                rounded={'md'}
                maxW={'max-content'}>
                <Stack>
                  {navItem.subElements.map(element => (
                    <DesktopSubNav key={element.label} pathname={pathname} {...element} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
    <FactorySelector />
  </HStack>
}

interface DesktopSubNavProps {
  label: string;
  subLabel?: string;
  to: string;
  icon: IconType;
  pathname: string;
}
const DesktopSubNav = ({ label, to, subLabel, icon, pathname }: DesktopSubNavProps) => {
  const { colorMode } = useColorMode();
  const isActive = pathname === to;
  const activeColor = "primary";
  const { hoverColor } = useMemo(() => {
    return {
      hoverColor: `${colorMode}.popOverHoverColor`
    }
  }, [colorMode]);
  return (
    <ChakraLink
      as={Link}
      to={to}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      color={isActive ? activeColor : "inherit"}
      _hover={{ bg: hoverColor }}>
      <Stack direction={'row'} align={'center'}>
        <Flex
          gap={2}
          alignItems={'center'}
          flexDir={"row"}>
          <Icon _groupHover={{ color: "orange.400" }} w={4} h={4} as={icon} />
          <Box>
            <Text
              fontSize={'sm'}
              transition={'all .3s ease'}
              _groupHover={{ color: 'orange.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
        </Flex>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'orange.400'} w={5} h={5} as={BiChevronRight} />
        </Flex>
      </Stack>
    </ChakraLink>
  );
};

export default DesktopNav;