import { Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, GridItem, HStack, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, SimpleGrid, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { cloneElement, isValidElement, useEffect, useMemo, useState } from "react";
import Primary from "../../../../Components/Buttons/Primary";
import { PrimaryInput } from "../../../../Components/Inputs";
import PrimarySelect from "../../../../Components/Inputs/PrimarySelect";
import BackdropLoader from "../../../../Components/Loaders/BackdropLoader"
import MinifiedNumberFormatter from "../../../../Components/micro/MinifiedNumberFormatter";
import MyModal from "../../../../Components/micro/MyModal";
import dayjs from "../../../../Components/Functions/dayjs";
import MyTooltip from "../../../../Components/micro/MyTooltip";
import minifiedSecFormatter from "../../../../Components/Functions/formatters/minifiedSecFormatter";
import { z } from "zod";
import { update } from "../../../../Components/firebase/api/db";
import { DataToSet } from "..";
import { increment, serverTimestamp } from "firebase/database";
import SearchableSelector from "../../../../Components/Inputs/SearchableSelector";
import { Option } from "../../../Reports/Selector/types";
import ConfirmDialog from "../../../../Components/micro/ConfirmDialog";

interface ErrorsType {
    name: string | null;
    target: string | null;
    hourlyTarget: string | null;
    due: string | null;
};
interface DataType {
    name: string;
    target: number;
    hourlyTarget: number;
    due?: string;
    machineMonitorDowntime: boolean;
    notifications: ("milestone" | "due-missed")[];
    notificationCategories: string[]
}
const formSchema = z
    .object({
        name: z.string().min(1).max(50),
        target: z.number().min(1),
        hourlyTarget: z.number().min(1),
        due: z.string().optional()
    }).superRefine(({ due }, ctx) => {
        if (dayjs(due).isBefore(dayjs().format("YYYY-MM-DD"))) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Due date cannot be in the past",
                path: ["due"]
            });
        }
    })
const AddUpdateTarget = ({
    children,
    machineID,
    target,
    refetch,
    mold,
    notificationCategories
}: {
    children?: JSX.Element,
    refetch: () => void,
    machineID?: string,
    target: {
        name: string,
        due?: string,
        target: number,
        hourlyTarget: number,
        defaultMonitorDowntime: boolean,
        machineMonitorDowntime: boolean,
        notifications: ("milestone" | "due-missed")[],
        notificationCategories: string[]
    },
    mold: DataToSet["mold"],
    notificationCategories: string[]
}) => {
    const toast = useToast();
    const hadTarget = useMemo(() => target.name !== "", [target]);
    const [loading, setLoading] = useState<string | null>(null);
    const [autoDueDate, setAutoDueDate] = useState<boolean>(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btn = isValidElement(children) ?
        cloneElement(children as JSX.Element, { onClick: onOpen })
        : <Button onClick={onOpen}>Open</Button>;
    const [errors, setErrors] = useState<ErrorsType>({
        name: null,
        target: null,
        hourlyTarget: null,
        due: null
    });
    const [myData, setMyData] = useState<DataType>(target);
    const expectedEnd = useMemo(() => {
        let now = dayjs().unix();
        if (!myData.target || !mold.cycleTime) return now;
        const shots = myData.target / mold.cavities;
        if (shots < 1) return now + mold.cycleTime;
        // round up to the nearest cycle time
        return now + (mold.cycleTime * Math.ceil(shots));
    }, [myData.target, mold]);
    const maxHourlyTarget = useMemo(() => {
        return Math.floor((3600 / mold.cycleTime) * mold.cavities);
    }, [mold]);

    const updateAutoDueDate = () => {
        if (!autoDueDate) return;
        if (myData.hourlyTarget) {
            const hours = myData.target / myData.hourlyTarget;
            setMyData({
                ...myData,
                due: dayjs().add(hours, "hour").format("YYYY-MM-DDTHH:mm")
            });
        } else {
            setMyData({
                ...myData,
                due: dayjs.unix(expectedEnd).add(1, "hour").format("YYYY-MM-DDTHH:mm")
            });
        }
    };

    useEffect(() => {
        updateAutoDueDate();
        // eslint-disable-next-line
    }, [autoDueDate, myData.target, myData.hourlyTarget, expectedEnd]);

    const endNow = async () => {
        setLoading("Ending Target");
        try {
            await update(`production-targets/active/${machineID}/${mold.name}/target`, {
                completed: true
            });
            setMyData({
                name: "",
                target: 0,
                hourlyTarget: 0,
                machineMonitorDowntime: false,
                notifications: [],
                notificationCategories
            });
            refetch();
            onClose();
            toast({
                title: "Success",
                description: "Target has been ended.",
                status: "success",
                duration: 5000,
                isClosable: true
            });
        } catch (err) {
            console.error(err);
            toast({
                title: "Error",
                description: "something went wrong.",
                status: "error",
                duration: 5000,
                isClosable: true
            });
        }
        setLoading(null);
    }

    const submit = async () => {
        setLoading("Adding Target");
        const results = formSchema.safeParse(myData);
        if (!results.success) {
            const errors = results.error.format();
            setErrors({
                name: errors.name?._errors.join(", ") || null,
                target: errors.target?._errors.join(", ") || null,
                hourlyTarget: errors.hourlyTarget?._errors.join(", ") || null,
                due: errors.due?._errors.join(", ") || null
            });
        } else {
            setErrors({
                name: null,
                target: null,
                hourlyTarget: null,
                due: null
            });
            try {
                const month = dayjs().format("YYYY-MM");
                const changeInTarget = !!(myData.target - target.target) || !!(myData.hourlyTarget - target.hourlyTarget);
                const total = changeInTarget ? myData.target - target.target : 0;
                const payload = {
                    name: myData.name,
                    startedAt: serverTimestamp(),
                    target: {
                        total: myData.target,
                        hourlyTarget: myData.hourlyTarget,
                        monitorDowntime: target.defaultMonitorDowntime || myData.machineMonitorDowntime,
                        current: changeInTarget ? 0 : target.target,
                        due: myData.due || null,
                    },
                    notifications: myData.notifications || null,
                    notificationCategories: myData.notificationCategories || null
                };
                await Promise.all([
                    update(`production-targets/active/${machineID}/${mold.name}`, payload),
                    update(`production-targets/statistics/${month}/machines/${machineID}/${mold.name}`, {
                        total: increment(total)
                    })
                ]);
                refetch();
                onClose();
                toast({
                    title: "Success",
                    description: "Target has been added.",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
            } catch (err) {
                console.error(err);
                toast({
                    title: "Error",
                    description: "something went wrong.",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            }
        }
        setLoading(null);
    };

    return <>
        {loading && <BackdropLoader text={loading} />}
        {btn}
        <MyModal
            size={"xl"}
            isOpen={isOpen}
            onClose={onClose}>
            <ModalHeader>{hadTarget ? "Update" : "Add"} Target</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <VStack gap={4}>
                    <HStack w="100%" gap={4}>
                        <FormControl isRequired isInvalid={errors.name !== null}>
                            <FormLabel>Name</FormLabel>
                            <PrimaryInput name="name" placeholder='Target Name' value={myData.name} onChange={e => setMyData({ ...myData, name: e.target.value })} />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Mold</FormLabel>
                            <PrimarySelect isDisabled fontWeight={700} textTransform="uppercase">
                                <option selected>{`${mold.name} (${Math.round(mold.cycleTime)}s)`}</option>
                            </PrimarySelect>
                        </FormControl>
                    </HStack>
                    <SimpleGrid columns={5} w="100%" gap={4}>
                        <FormControl as={GridItem} colSpan={3} isRequired isInvalid={errors.target !== null}>
                            <FormLabel>Target <span style={{ fontSize: "0.7rem", opacity: 0.8 }}>pc(s)</span></FormLabel>
                            <PrimaryInput name="target" placeholder='Target Quantity (pcs)' type="number" value={myData.target} onChange={e => setMyData({ ...myData, target: +e.target.value })} />
                            <FormErrorMessage>{errors.target}</FormErrorMessage>
                        </FormControl>
                        <FormControl as={GridItem} colSpan={2} isInvalid={errors.hourlyTarget !== null}>
                            <FormLabel>H. Target <span style={{ fontSize: "0.7rem", opacity: 0.8 }}>pc(s)</span></FormLabel>
                            <PrimaryInput name="hourlyTarget" placeholder='Target Quantity (pcs)' type="number" value={myData.hourlyTarget} onChange={e => setMyData({ ...myData, hourlyTarget: +e.target.value })} />
                            <FormErrorMessage>{errors.hourlyTarget}</FormErrorMessage>
                        </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={5} w="100%" gap={4}>
                        <FormControl as={GridItem} colSpan={2} isRequired>
                            <FormLabel>Monitor Downtime</FormLabel>
                            <PrimarySelect name={"monitor-downtime"} isDisabled={target.defaultMonitorDowntime} defaultValue={target.defaultMonitorDowntime || target.machineMonitorDowntime ? "Yes" : "No"} onChange={e => setMyData({ ...myData, machineMonitorDowntime: e.target.value === "Yes" })}>
                                <option>Yes</option>
                                <option>No</option>
                            </PrimarySelect>
                        </FormControl>
                        <FormControl as={GridItem} colSpan={3} isInvalid={errors.due !== null}>
                            <Flex p={1} w="100%" justifyContent={"space-between"} flexDir="row">
                                <Text w="fit-content">Due Date</Text>
                                <Checkbox
                                    w="fit-content"
                                    size="sm"
                                    colorScheme="orange"
                                    isChecked={autoDueDate}
                                    onChange={e => setAutoDueDate(e.target.checked)}>
                                    Auto
                                </Checkbox>
                            </Flex>
                            <PrimaryInput isDisabled={autoDueDate} name="due" placeholder='Due Date' type="datetime-local" value={myData.due} onChange={e => setMyData({ ...myData, due: e.target.value })} />
                            <FormErrorMessage>{errors.due}</FormErrorMessage>
                        </FormControl>
                    </SimpleGrid>
                    <SimpleGrid columns={2} w="100%" gap={4}>
                        <FormControl>
                            <FormLabel fontSize="xs">Notifications</FormLabel>
                            <SearchableSelector
                                isMulti
                                closeMenuOnSelect={false}
                                size="sm"
                                options={[
                                    { label: "Milestone", value: "milestone" },
                                    { label: "Due Missed", value: "due-missed" },
                                ]}
                                onChange={(e: Option[]) => setMyData({ ...myData, notifications: e.map(({ value }) => value) as DataType["notifications"] })}
                                value={myData.notifications.map(notification => ({
                                    label: notification,
                                    value: notification
                                }))}
                                name={"notifications"} />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize="xs">Notify</FormLabel>
                            <SearchableSelector
                                isMulti
                                isDisabled={!myData.notifications.length}
                                closeMenuOnSelect={false}
                                size="sm"
                                onChange={(e: Option[]) => setMyData({ ...myData, notificationCategories: e.map(({ value }) => value) })}
                                options={notificationCategories.map(category => ({
                                    label: category,
                                    value: category
                                }))}
                                value={myData.notificationCategories.map(notification => ({
                                    label: notification,
                                    value: notification
                                }))}
                                name={"notifications"} />
                        </FormControl>
                    </SimpleGrid>
                </VStack>
            </ModalBody>
            <ModalFooter>
                <HStack w="100%" justifyContent={"center"}>
                    <VStack opacity={0.8} fontSize="sm" w="100%" alignItems={"flex-start"}>
                        <Text>Target: <strong style={{
                            opacity: 1
                        }}><MinifiedNumberFormatter number={myData.target} suffix="pc(s)" /></strong></Text>
                        <Text>Max H. Target: <strong style={{
                            opacity: 1
                        }}><MinifiedNumberFormatter number={maxHourlyTarget} suffix="pc(s)" /></strong></Text>
                        <Text>Expected End: <strong style={{
                            opacity: 1
                        }}><MyTooltip
                            label={
                                dayjs.unix(expectedEnd).format("DD-MM-YYYY HH:mm:ss")
                            }>
                                {`in ${minifiedSecFormatter(Math.abs(dayjs().diff(dayjs.unix(expectedEnd), "second")))}`}
                            </MyTooltip></strong></Text>
                    </VStack>
                    <VStack alignItems={"flex-end"}>
                        <Primary size="sm" onClick={submit}>{hadTarget ? "Update " : "Add "}Target</Primary>
                        <ConfirmDialog onConfirm={endNow} scope="danger">
                            <Button size="sm" colorScheme="red" variant="outline" isDisabled={!hadTarget}>End Now</Button>
                        </ConfirmDialog>
                    </VStack>
                </HStack>
            </ModalFooter>
        </MyModal>
    </>
}

export default AddUpdateTarget