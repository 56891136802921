import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    Flex,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    useColorMode,
    useDisclosure,
    useToast,
    Icon,
    ToastId
} from "@chakra-ui/react";
import { FC, ReactNode, useEffect, useMemo } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link, useLocation } from "react-router-dom";
import routes from "./routes";
import type { Route } from "./types";
import { IoIosArrowDown } from "react-icons/io";
import store from "../Components/Store";
import { uidAtom, dateAtom, companyNameAtom, departmentsAtom, machinesAtom, departmentAtom, roleAtom, shiftsAtom, factoriesAtom, selectedFactoryAtom, equipmentsAtom, EnvListAtom, EnvUnitListAtom, machinesOrderAtom, isWebViewAppAtom, offtimeBasedEfficiencyAtom, offlineTimeAtom, factoryProductionType, factoryProductionAtom } from "../Components/Store/atoms";
import { auth, messaging } from "../Components/firebase";
import { get, listen, rawGet, set, update } from "../Components/firebase/api/db";
import dayjs from "../Components/Functions/dayjs";
import { DataSnapshot, serverTimestamp } from "firebase/database";
import { REST_SERVER_URL } from "../Components/micro/sendDataRest";
import { TbWaveSawTool } from "react-icons/tb";
import { getToken } from "../Components/firebase/api/messaging";
import { onMessage, Unsubscribe } from "firebase/messaging";

type LayoutProps = {
    children: ReactNode
};

const Index: FC<LayoutProps> = ({
    children
}) => {
    const user = auth.currentUser;
    const { onClose, onOpen, isOpen } = useDisclosure();
    const { colorMode } = useColorMode();
    const bgColor = `${colorMode}.bg`;
    const location = useLocation();
    const toast = useToast();
    const { pathname } = location;
    const headerInfo: Route = useMemo(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const splitted = pathname.split("/");
        let route = routes[pathname];
        if (!route && splitted.length > 1 && splitted.at(3) !== "power") route = routes[`/${splitted[1]}`];
        if (!route) return {} as Route;
        return { ...route };
    }, [pathname]);
    const { popoverContentBgColor, hoverColor } = useMemo(() => {
        return {
            popoverContentBgColor: `${colorMode}.popoverContentBgColor`,
            hoverColor: `${colorMode}.popOverHoverColor`
        }
    }, [colorMode]);

    const setDate = async (info: any = undefined) => {
        let shift_a_start: undefined | number;
        if (info) {
            shift_a_start = +info.shift_a_start;
        } else {
            const selectedFactory = store.get(selectedFactoryAtom);
            if (selectedFactory === null || selectedFactory.value === -1) {
                const shift_a_start_ref = await get('info/shift_a_start');
                shift_a_start = +shift_a_start_ref.val();
            } else {
                const shift_a_start_ref = await get(`factories/${selectedFactory.value}/info/shift_a_start`);
                shift_a_start = +shift_a_start_ref.val();
            }
        }
        const rn = dayjs();
        const rn_time = rn.hour() * 60 * 60 + rn.minute() * 60;
        const date = (shift_a_start > rn_time ? rn.subtract(1, "days") : rn).format("YYYY-MM-DD");
        store.set(dateAtom, date);
    };

    const registerDevice = async (token: string | null, platform: string | null, model: string | null, uid: string) => {
        if (process.env.NODE_ENV === "development") return;
        if (!token || !platform) {
            // fetch this device's FCM token and info
            const permission = await Notification.requestPermission();
            if (permission !== "granted") return;
            const newToken = await getToken();
            if (!newToken) return;
            const agentData = (navigator as any).userAgentData;
            platform = agentData.platform;
            token = newToken;
            model = (agentData.brands?.at(-1)?.brand === "Not.A/Brand" ? agentData.brands?.at(0)?.brand : agentData.brands?.at(-1)?.brand) || "NA";
        }

        await update(`devices/${token}`, {
            platform,
            model,
            status: true
        });
        onMessage(messaging, async (payload) => {
            if (!payload.notification) return;
            const { title, body } = payload.notification;
            toast({
                title,
                description: body,
                status: "info",
                position: "bottom-right",
                duration: 5000,
                isClosable: true,
            });
        });
    };

    // fetch all details and set in store
    useEffect(() => {
        const func = async () => {
            if (!user) return;
            let myUid = user.uid;
            const ref = await rawGet(`users/${myUid}/masterUid`);
            if (ref.exists()) {
                myUid = ref.val();
                set(`users/${myUid}/sub-users/${user.uid}/lastLogin`, serverTimestamp());
            }
            const isSubUser = myUid !== user.uid;
            store.set(uidAtom, myUid);

            const refs = [
                get(`info`),
                get(`factories`),
                get(`departments`),
                get(`machines`),
                get(`equipments`),
                rawGet(`users/${user.uid}/role`),
                rawGet(`users/${user.uid}/department`),
                get("env_list"),
                get(`address`)
            ];
            const [infoRef, factoriesRef, departmentsRef, machinesRef, equipmentsRef, roleRef, departmentRef, envRef, addressRef] = await Promise.all(refs);
            const address = addressRef.val() || {};
            const factories = factoriesRef.val() || {} as object;
            const selectedFactoryLS = JSON.parse(localStorage.getItem('selectedFactory') || "{}").value || 0;
            const selectedFactory = factoriesRef.exists() ? selectedFactoryLS === -1 ? {
                name: "Total",
                value: -1,
                only: false
            } : {
                name: factories[selectedFactoryLS].info.company_name as string,
                value: selectedFactoryLS,
                only: false
            } : {
                name: "Total",
                value: -1,
                only: true
            };
            const actualInfo = infoRef.val();

            const _infoData = factoriesRef.exists() ? factories[(selectedFactory.value === -1 ? 0 : selectedFactory.value) || 0].info : actualInfo;
            const offlineTime = actualInfo.offline_time || 3;

            setInterval(setDate, 60000);
            setDate(_infoData);
            interface infoType {
                company_name: string,
                shifts: number,
                shift_a_start: number,
                shift_b_start: number,
                shift_c_start: number
            }
            const info: infoType = _infoData as infoType || {
                company_name: "",
                shifts: 1,
                shift_a_start: 0,
                shift_b_start: 0,
                shift_c_start: 0
            };
            const offtimeBasedEfficiency = !!actualInfo.off_time_based_efficiency;
            const company_name = actualInfo.company_name;
            const factoryProduction: factoryProductionType = actualInfo.production_type || "molding";
            const shifts = {
                shifts: +info.shifts as 1 | 2 | 3,
                A: +info.shift_a_start,
                B: +info.shift_b_start,
                C: +info.shift_c_start
            }
            const departments = departmentsRef.val() || {} as object;
            const sortedMachineEntries = Object.entries(machinesRef.val() as object || {}).sort((a, b) => {
                const aOrder = a[1].sort as string;
                const bOrder = b[1].sort as string;
                if (aOrder === bOrder) return 0;
                return aOrder > bOrder ? 1 : -1;
            });
            const machinesOrder = sortedMachineEntries.map(([key, value]) => key);
            const rawMachines = Object.fromEntries(sortedMachineEntries);
            const rawEquipments = equipmentsRef.val() || {} as object;
            const department = departmentRef.val() as ["all"] | string[] || ["all"];
            const equipments: {
                [key: string]: string
            } = {};
            for await (const [post, equipment] of Object.entries(rawEquipments)) {
                if (isSubUser) {
                    let found = false;
                    for (const depart of department) {
                        if (depart === "all") found = true;
                        if (departments[depart]?.equipments?.includes(post)) found = true;
                        if (found) break;
                    }
                    if (!found) continue;
                }
                const { name } = equipment as any;
                equipments[post] = name;
            }
            const role = roleRef.val() as "editor" | "viewer" || "editor";
            const machines: {
                [key: string]: string
            } = {};
            for await (const [post, machine] of Object.entries(rawMachines)) {
                if (isSubUser) {
                    let found = false;
                    for (const depart of department) {
                        if (depart === "all") found = true;
                        if (departments[depart]?.machines?.includes(post)) found = true;
                        if (found) break;
                    }
                    if (!found) continue;
                }
                const { machine_model } = machine as any;
                machines[post] = machine_model;
            }
            const envList: {
                [key: string]: string
            } = {};
            for (const [key, value] of Object.entries(envRef.val() || {})) {
                if (isSubUser) {
                    let found = false;
                    for (const depart of department) {
                        if (depart === "all") found = true;
                        if (departments[depart]?.envs?.includes(key)) found = true;
                        if (found) break;
                    }
                    if (!found) continue;
                }
                envList[key as string] = value as string;
            }
            const envUnitList: {
                [key: string]: number
            } = {};
            for (const unit in address) {
                const data = address?.[unit].extras;
                if (data === undefined) continue;
                const env = data?.dht_humid;
                if (env === undefined) continue;
                envUnitList[env] = +unit;
            }
            // device FCM token
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            const token = searchParams.get("token") || searchParams.get("?token");
            const platform = searchParams.get("platform");
            const model = searchParams.get("model");
            const device = searchParams.get("device") + "-" + model;
            const isWebViewApp = !!(token || platform || model);
            try {
                registerDevice(token, platform, device, myUid);
            } catch (e) {
                console.error(e);
            }

            store.set(isWebViewAppAtom, isWebViewApp);
            store.set(machinesOrderAtom, machinesOrder);
            store.set(EnvUnitListAtom, envUnitList);
            store.set(EnvListAtom, envList);
            store.set(companyNameAtom, company_name);
            store.set(departmentsAtom, departments);
            store.set(machinesAtom, machines);
            store.set(equipmentsAtom, equipments);
            store.set(roleAtom, role);
            store.set(departmentAtom, department);
            store.set(shiftsAtom, shifts);
            store.set(factoriesAtom, factories);
            store.set(selectedFactoryAtom, selectedFactory);
            store.set(offtimeBasedEfficiencyAtom, offtimeBasedEfficiency);
            store.set(offlineTimeAtom, offlineTime);
            store.set(factoryProductionAtom, factoryProduction);
        }
        func();

        return () => {
            store.set(offtimeBasedEfficiencyAtom, null);
            store.set(isWebViewAppAtom, null);
            store.set(EnvListAtom, null);
            store.set(uidAtom, null);
            store.set(dateAtom, null);
            store.set(companyNameAtom, null);
            store.set(departmentsAtom, null);
            store.set(machinesAtom, null);
            store.set(equipmentsAtom, null);
            store.set(roleAtom, null);
            store.set(departmentAtom, null);
            store.set(shiftsAtom, null);
            store.set(factoriesAtom, null);
            store.set(selectedFactoryAtom, null);
            store.set(factoryProductionAtom, null);
            store.set(offlineTimeAtom, 3);
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (!user) return;
        let maintenanceToastId: ToastId | undefined;
        let unsub: Unsubscribe;
        const func = async () => {
            const headers = {
                "Content-Type": "application/json",
                uid: "PC-TIME-CHECK",
                unit: "1",
            };
            const callback = (snap: DataSnapshot) => {
                const isUnderMaintenance = !!snap.val();
                if (isUnderMaintenance) maintenanceToastId = toast({
                    title: "Under Maintenance",
                    description: "industrialpmr services are currently undergoing maintenance to enhance your experience. Please check back shortly. Thank you for your patience!",
                    status: "info",
                    position: "bottom-right",
                    duration: null,
                    isClosable: true,
                    icon: <Icon as={TbWaveSawTool} fontSize="2xl" />
                });
                else if (maintenanceToastId) toast.close(maintenanceToastId);
            };
            unsub = listen(`admin/alerts/is-under-maintenance`, callback);
            // rawGet(`admin/alerts/is-under-maintenance`),
            const res = await fetch(REST_SERVER_URL + "/time", {
                method: "GET",
                headers: headers,
            });
            res.json().then((data: {
                date: number;
                day: number;
                hour: number;
                microseconds: number;
                minute: number;
                month: number;
                second: number;
                year: number;
            }) => {
                // will check if the time on local machine is more than 60s appart, then error
                const now = dayjs();
                const then = dayjs(`${data.year}-${data.month}-${data.date} ${data.hour}:${data.minute}:${data.second}`);
                const diff = Math.abs(now.diff(then, "seconds"));
                if (diff > 60) toast({
                    title: "System time incorrect",
                    description: "Please set the system time correctly with proper timezone",
                    status: "error",
                    position: "bottom-right",
                    duration: null,
                    isClosable: false,
                });
            }).catch(e => {
                console.error(e)
            })
        }
        func();

        return () => {
            unsub();
            if (maintenanceToastId) toast.close(maintenanceToastId);
        }
    }, [user, toast]);

    return <Box
        minH="100vh"
        bgColor={bgColor}
        w="100%">
        <Navbar />
        <Box
            minH="100vh"
            px={"5%"}
            py={"1%"}>
            <Flex
                mb={5}
                justifyContent={{
                    base: "center",
                    lg: "space-between"
                }}
                flexDir={{
                    base: "column",
                    lg: "row"
                }}>
                <Text
                    my="5px"
                    fontSize={16}
                    textAlign={{
                        base: "center",
                        lg: "left"
                    }}
                    fontWeight={600}
                    textTransform={"uppercase"}>{headerInfo.title}</Text>
                <Breadcrumb
                    display="flex"
                    fontSize={12}
                    spacing='8px'
                    separator={<ChevronRightIcon color='gray.500' />}>
                    {
                        headerInfo.breadcumbs?.map((breadcrumb, index) => {
                            return breadcrumb.children ? <Popover
                                key={index}
                                trigger="hover"
                                isOpen={isOpen}
                                onOpen={onOpen}
                                onClose={onClose}>
                                <PopoverTrigger>
                                    <Text
                                        gap={1}
                                        alignItems={"center"}
                                        display={"flex"}
                                        textTransform={"capitalize"}
                                        opacity={0.8}
                                        fontSize="xs"
                                        fontWeight="medium"
                                        letterSpacing={"wider"}
                                        cursor="pointer">
                                        <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
                                        <IoIosArrowDown />
                                    </Text>
                                </PopoverTrigger>
                                <PopoverContent
                                    border={0}
                                    boxShadow={'xl'}
                                    bg={popoverContentBgColor}
                                    p={1.5}
                                    rounded={'md'}
                                    maxW={'max-content'}>
                                    <PopoverBody>
                                        {breadcrumb.children.map((child, secondIndex) => <Stack key={secondIndex}>
                                            <Text
                                                as={Link}
                                                to={child.link}
                                                cursor="pointer"
                                                _hover={{
                                                    bg: hoverColor,
                                                    color: "orange.400"
                                                }}
                                                p={1}
                                                textTransform={"capitalize"}
                                                rounded={'md'}
                                                fontSize="xs"
                                                fontWeight="medium"
                                                letterSpacing={"wider"}>{child.title}</Text>
                                        </Stack>
                                        )}
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                                : <BreadcrumbItem key={index}>
                                    <Link to={breadcrumb.link}>{breadcrumb.title}</Link>
                                </BreadcrumbItem>
                        })
                    }
                </Breadcrumb>
            </Flex>
            {children}
        </Box>
        <Footer />
    </Box>
}

export default Index;