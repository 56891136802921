import dayjs from "../dayjs";
dayjs.extend(require("dayjs/plugin/duration"));

const minifiedSecFormatter = (seconds: number): string => {
  const duration = dayjs.duration(seconds, "seconds");

  const weeks = Math.floor(duration.asWeeks());
  if (weeks > 0) {
    return `${weeks}w`;
  }

  const days = Math.floor(duration.asDays());
  if (days > 0) {
    return `${days}d`;
  }

  const hours = Math.floor(duration.asHours());
  if (hours > 0) {
    return `${hours}h`;
  }

  const minutes = Math.floor(duration.asMinutes());
  if (minutes > 0) {
    return `${minutes}m`;
  }

  return `${seconds}s`;
};

export default minifiedSecFormatter;
