import { auth } from "../../firebase";

const emailAuth = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const { uid } = auth.currentUser || {};
    if (!uid) reject("User not logged in");
    const func = async () => {
      const URL =
        process.env[`REACT_APP_${process.env.NODE_ENV.toUpperCase()}_API_URL`];
      const resp = await fetch(`${URL}/send-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (resp.ok) resolve("OK");
      else reject("Something went wrong");
    };
    func();
  });
};

export default emailAuth;
