import secToTime from "../converters/secondsToHourMin";
import numberFormatter from "../formatters/numberFormatter";
import weightFormatter from "../formatters/weightFormatter";
import unitFormatter from "../formatters/unitFormatter";
import secFormatter from "./secFormatter";
import { SortedHourTree } from "../../../routes/Machines/Machine/types";
import lengthFormatter from "./lengthFormatter";

interface RawHours {
  start: number;
  end: number;
  shots: number;
  production_meters: number;
  production: number;
  material: number;
  electricity: number;
  ontime: number;
  offtime: number;
  idletime: number;
  cycleTime: number;
  operator: string[];
  mold: string[];
  product: string[];
  children?: RawHours[];
}
type ShiftKeys = "A" | "B" | "C";

const sortHourlyTree = async (
  shifts: {
    A: number;
    B: number;
    C: number;
    shifts: number;
  },
  records: any[],
): Promise<SortedHourTree[]> => {
  if (!records) return [];
  records = Object.values(records).sort((a, b) => a.time - b.time);

  //   set raw hours
  const hours: RawHours[] = [];
  const shiftsKeys: ShiftKeys[] = ["A", "B", "C"];
  for (let i = 0; i < shifts.shifts; i++) {
    const next = i + 1 === shifts.shifts ? 0 : i + 1;
    let start = shifts[shiftsKeys[i]];
    let end = shifts[shiftsKeys[next]] + 59;
    if (start > end) {
      end += 86400;
    }
    hours.push({
      start,
      end,
      shots: 0,
      production_meters: 0,
      production: 0,
      material: 0,
      electricity: 0,
      ontime: 0,
      offtime: 0,
      idletime: 0,
      cycleTime: 0,
      operator: [],
      mold: [],
      children: [],
      product: [],
    });
  }
  for (let i = 0; i < 24; i++) {
    const start = shifts.A + i * 3600;
    const end = shifts.A + (i + 1) * 3600 + 59;
    const shiftIndex = hours.findIndex(
      (hour) => start >= hour.start && end <= hour.end,
    );
    if (shiftIndex === -1) continue;
    const hour = hours[shiftIndex];
    hour.children?.push({
      start,
      end,
      shots: 0,
      production_meters: 0,
      production: 0,
      material: 0,
      electricity: 0,
      ontime: 0,
      offtime: 0,
      idletime: 0,
      cycleTime: 0,
      operator: [],
      mold: [],
      children: [],
      product: [],
    });
  }

  for await (const _hour of records) {
    const hour = _hour as any;
    const start = new Date(hour.from * 1000).getTime() / 1000;
    const end = new Date(hour.time * 1000).getTime() / 1000;

    // shift
    const shiftIndex = hours.findIndex(
      (hour) => start >= hour.start && end <= hour.end,
    );
    if (shiftIndex === -1) continue;
    const shiftHour = hours[shiftIndex];
    const product = `${hour.product}(${hour.product_color})`;
    const cycleTime = hour.production_meters
      ? hour.ontime && hour.production_meters
        ? hour.ontime / hour.production_meters
        : 0
      : hour.ontime === 0 || hour.shots === 0
      ? 0
      : hour.ontime / hour.shots;
    shiftHour.shots += hour.shots;
    shiftHour.production_meters += hour.production_meters || 0;
    shiftHour.production += hour.production;
    shiftHour.material += hour.material_usage;
    shiftHour.electricity += hour.electricity_usage;
    shiftHour.ontime += hour.ontime;
    shiftHour.offtime += hour.shutdown_time || 0;
    shiftHour.idletime += hour.offtime;
    shiftHour.cycleTime = shiftHour.production_meters
      ? shiftHour.ontime && shiftHour.production_meters
        ? shiftHour.ontime / shiftHour.production_meters
        : 0
      : shiftHour.shots && shiftHour.ontime
      ? shiftHour.ontime / shiftHour.shots
      : 0;
    if (!shiftHour.operator.includes(hour.operator))
      shiftHour.operator.push(hour.operator);
    if (!shiftHour.mold.includes(hour.mold_name))
      shiftHour.mold.push(hour.mold_name);
    if (!shiftHour.product.includes(product)) shiftHour.product.push(product);

    //hour
    const hourIndex = shiftHour.children?.findIndex(
      (hour) => start >= hour.start && end <= hour.end,
    );
    if (hourIndex === undefined || hourIndex === -1) {
      continue;
    }
    if (!shiftHour.children?.[hourIndex]) continue;
    const hourChild = shiftHour.children[hourIndex];
    hourChild.shots += hour.shots;
    hourChild.production_meters += hour.production_meters || 0;
    hourChild.production += hour.production;
    hourChild.material += hour.material_usage;
    hourChild.electricity += hour.electricity_usage;
    hourChild.ontime += hour.ontime;
    hourChild.offtime += hour.shutdown_time || 0;
    hourChild.idletime += hour.offtime;
    hourChild.cycleTime = hour.production_meters
      ? hourChild.production_meters && hourChild.ontime
        ? hourChild.ontime / hourChild.production_meters
        : 0
      : hourChild.shots && hourChild.ontime
      ? hourChild.ontime / hourChild.shots
      : 0;
    if (!hourChild.operator.includes(hour.operator))
      hourChild.operator.push(hour.operator);
    if (!hourChild.mold.includes(hour.mold_name))
      hourChild.mold.push(hour.mold_name);
    if (!hourChild.product.includes(product)) hourChild.product.push(product);

    // last record
    hourChild.children = hourChild.children || [];
    hourChild.children.push({
      start,
      end,
      shots: hour.shots,
      production_meters: hour.production_meters || 0,
      production: hour.production,
      material: hour.material_usage,
      electricity: hour.electricity_usage,
      ontime: hour.ontime,
      offtime: hour.shutdown_time || 0,
      idletime: hour.offtime,
      cycleTime: cycleTime,
      operator: hour.operator,
      mold: hour.mold_name,
      product: product as any,
    });
    shiftHour.children[hourIndex] = hourChild;
  }

  //   sort
  const sortedHours: SortedHourTree[] = [];
  for (const [shiftIndex, shift] of Object.entries(hours)) {
    const ontime = shift.ontime > 0;
    const offtime = shift.offtime > 0;
    const idletime = shift.idletime > 0;
    let _status: any = {};
    if (ontime) _status["ON"] = true;
    if (offtime) _status["OFF"] = true;
    if (idletime) _status["IDLE"] = true;
    const status = Object.keys(_status).join(", ");
    const sortedShift: SortedHourTree = {
      key: shiftIndex.toString(),
      data: {
        time: secToTime(shift.start) + " - " + secToTime(shift.end),
        status,
        shots: numberFormatter(shift.shots, "shot(s)"),
        production_meters: lengthFormatter(shift.production_meters),
        production: numberFormatter(shift.production, "pc(s)"),
        material: weightFormatter(shift.material),
        electricity: unitFormatter(shift.electricity),
        ontime: secFormatter(shift.ontime),
        offtime: secFormatter(shift.offtime),
        idletime: secFormatter(shift.idletime),
        cycleTime: shift.cycleTime.toFixed(0) + "s",
        operator: shift.operator.join(", "),
        mold: shift.mold.join(", "),
        product: shift.product.join(", "),
      },
      children: [],
    };
    for await (const [hourIndex, hour] of Object.entries(
      shift.children || [],
    )) {
      const ontime = hour.ontime > 0;
      const offtime = hour.offtime > 0;
      const idletime = hour.idletime > 0;
      let _status: any = {};
      if (ontime) _status["ON"] = true;
      if (offtime) _status["OFF"] = true;
      if (idletime) _status["IDLE"] = true;
      const status = Object.keys(_status).join(", ");
      const sortedHour: SortedHourTree = {
        key: `${shiftIndex.toString()}-${hourIndex.toString()}`,
        data: {
          time: secToTime(hour.start) + " - " + secToTime(hour.end),
          status,
          shots: numberFormatter(hour.shots, "shot(s)"),
          production_meters: lengthFormatter(hour.production_meters),
          production: numberFormatter(hour.production, "pc(s)"),
          material: weightFormatter(hour.material),
          electricity: unitFormatter(hour.electricity),
          ontime: secFormatter(hour.ontime),
          offtime: secFormatter(hour.offtime),
          idletime: secFormatter(hour.idletime),
          cycleTime: hour.cycleTime.toFixed(0) + "s",
          operator: hour.operator.join(", "),
          mold: hour.mold.join(", "),
          product: hour.product.join(", "),
        },
        children: [],
      };
      for await (const [recordIndex, record] of Object.entries(
        hour.children || [],
      )) {
        const ontime = record.ontime > 0;
        const offtime = record.offtime > 0;
        const idletime = record.idletime > 0;
        let _status: any = {};
        if (ontime) _status["ON"] = true;
        if (offtime) _status["OFF"] = true;
        if (idletime) _status["IDLE"] = true;
        const status = Object.keys(_status).join(", ");
        const sortedRecord: SortedHourTree = {
          key: `${shiftIndex.toString()}-${hourIndex.toString()}-${recordIndex.toString()}`,
          data: {
            time: secToTime(record.start) + " - " + secToTime(record.end),
            status,
            shots: numberFormatter(record.shots, "shot(s)"),
            production_meters: lengthFormatter(record.production_meters),
            production: numberFormatter(record.production, "pc(s)"),
            material: weightFormatter(record.material),
            electricity: unitFormatter(record.electricity),
            ontime: secFormatter(record.ontime),
            offtime: secFormatter(record.offtime),
            idletime: secFormatter(record.idletime),
            cycleTime: record.cycleTime.toFixed(0) + "s",
            operator: record.operator.toString(),
            mold: record.mold.toString(),
            product: record.product.toString(),
          },
        };
        sortedHour.children?.push(sortedRecord);
      }
      sortedShift.children?.push(sortedHour);
    }
    sortedHours.push(sortedShift);
  }
  return sortedHours;
};

export default sortHourlyTree;
