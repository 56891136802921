import { BiCube, BiNetworkChart } from "react-icons/bi";
import {
  HiOutlineLightningBolt,
  HiOutlineChartBar,
  HiOutlineChartPie,
} from "react-icons/hi";
import { IconType } from "react-icons/lib";
import { FiBarChart2, FiSettings } from "react-icons/fi";
import { VscDashboard } from "react-icons/vsc";
import { FaBoxes, FaThermometerHalf } from "react-icons/fa";
import {
  LuFilePieChart,
  // LuGanttChart,
  LuPieChart,
} from "react-icons/lu";

interface NavItem {
  label: string;
  to: string;
  icon: IconType;
  subElements?: Array<NavItem>;
}
const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Dashboard",
    to: "/",
    icon: VscDashboard,
    subElements: [
      {
        label: "Machines",
        to: "/machines",
        icon: HiOutlineChartPie,
      },
      {
        label: "Equipments",
        to: "/equipments",
        icon: HiOutlineChartBar,
      },
      {
        label: "Environments",
        to: "/environments",
        icon: FaThermometerHalf,
      },
      {
        label: "Main Electricity",
        to: "/main-electricity",
        icon: HiOutlineLightningBolt,
      },
    ],
  },
  {
    label: "Production Targets",
    to: "/targets",
    icon: LuFilePieChart,
    subElements: [
      {
        label: "Statistics",
        to: "/targets/stats",
        icon: LuPieChart,
      },
      // {
      //   label: "Down Times",
      //   to: "/targets/down-time",
      //   icon: LuGanttChart,
      // },
    ],
  },
  {
    label: "Departments",
    to: "/departments",
    icon: BiNetworkChart,
  },
  {
    label: "Reports",
    to: "/reports",
    icon: FiBarChart2,
  },
  {
    label: "Raw Materials",
    to: "/raw-materials",
    icon: BiCube,
    subElements: [
      {
        label: "Manage Inventory",
        to: "/raw-materials/manage",
        icon: FaBoxes,
      },
    ],
  },
  {
    label: "Settings",
    to: "/settings",
    icon: FiSettings,
  },
];

export default NAV_ITEMS;
