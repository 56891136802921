import { Button, FormControl, FormLabel, HStack, ModalBody, ModalCloseButton, ModalFooter, ModalHeader, Text, Textarea, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { cloneElement, isValidElement, useMemo, useState } from "react";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import { TbTimelineEventPlus } from "react-icons/tb";
import { useParams } from "react-router-dom";
import Primary from "../../../../Components/Buttons/Primary";
import { update } from "../../../../Components/firebase/api/db";
import secFormatter from "../../../../Components/Functions/formatters/secFormatter";
import PrimarySelect from "../../../../Components/Inputs/PrimarySelect";
import SearchableSelector from "../../../../Components/Inputs/SearchableSelector";
import BackdropLoader from "../../../../Components/Loaders/BackdropLoader"
import ConfirmDialog from "../../../../Components/micro/ConfirmDialog";
import MyModal from "../../../../Components/micro/MyModal";
import { machinesAtom } from "../../../../Components/Store/atoms";
import Get from "../../../../Components/Store/hooks/Get";
import downtimeCategories, { downtimeCategoriesType } from "./categories";

const Categorize = ({
    downtime,
    updateLog,
    children
}: {
    downtime: {
        start: number;
        end: number;
        reason?: {
            category: string;
            note: string;
            scheduled: boolean;
        };
        id: string;
    } | string,
    updateLog: (log: {
        start: number;
        end: number;
        reason: {
            category: downtimeCategoriesType;
            note: string;
            scheduled: boolean;
        };
        id: string;
    }) => void,
    children?: JSX.Element
}) => {
    const toast = useToast();
    const { machineID } = useParams<{ machineID: string }>();
    const [loading, setLoading] = useState<string | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const machines = Get(machinesAtom);
    const [data, setData] = useState({
        category: "uncategorized",
        note: "",
        scheduled: false
    });
    const machineName = useMemo(() => {
        if (!machines || !machineID) return machineID;
        return machines[machineID];
    }, [machines, machineID]);

    const add = async () => {
        if (typeof downtime === "string") {
            toast({
                title: "Downtime not found",
                description: "The downtime you are trying to categorize was not found",
                status: "error",
                isClosable: true
            });
            return;
        }
        setLoading("Adding");
        try {
            if (!downtimeCategories.includes(data.category as downtimeCategoriesType)) throw new Error("Invalid category");
            const payload = {
                category: data.category as downtimeCategoriesType,
                note: data.note,
                scheduled: data.scheduled
            }
            await update(`downtime/${machineID}/${downtime.id}/reason`, payload);
            updateLog({
                ...downtime,
                reason: payload
            });
            onClose();
            setData({
                category: "",
                note: "",
                scheduled: false
            });
            toast({
                title: "Downtime categorized",
                description: "The downtime has been categorized successfully",
                status: "success",
                isClosable: true
            });
        } catch (err) {
            console.error(err);
            toast({
                title: "Failed to categorize downtime",
                description: "An error occurred while trying to categorize downtime",
                status: "error",
                isClosable: true
            });
        } finally {
            setLoading(null);
        }
    };
    const btn = isValidElement(children) ? cloneElement(children as JSX.Element, { onClick: typeof downtime !== "string" ? onOpen : undefined, }) : <Button py={3} colorScheme="orange" variant="outline" size="xs" my={2} leftIcon={<TbTimelineEventPlus />} isDisabled={typeof downtime === "string" || downtime.reason !== undefined} cursor="pointer" onClick={onOpen}>Categorize</Button>;

    return typeof downtime !== "string" ? <>
        {loading && <BackdropLoader text={loading} />}
        {btn}
        <MyModal
            size={"xl"}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}>
            <ModalHeader>Categorize Downtime</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <HStack pb={10} pt={3} w="100%" justifyContent={"space-between"}>
                    <Text fontWeight={600} fontSize="xl" textDecor="underline">{machineName}</Text>
                    <Text fontWeight={500} fontSize="lg">{downtime.end ? secFormatter(downtime.end - downtime.start) : "Ongoing"}</Text>
                </HStack>
                <VStack gap={2}>
                    <HStack w="100%">
                        <FormControl isRequired>
                            <FormLabel fontSize="sm">Planned/Unplanned</FormLabel>
                            <PrimarySelect size="sm" value={data.scheduled ? "planned" : "unplanned"} onChange={e => {
                                setData(prev => ({
                                    ...prev,
                                    scheduled: e.target.value === "planned"
                                }));
                            }}>
                                <option value="planned">Planned</option>
                                <option value="unplanned">Unplanned</option>
                            </PrimarySelect>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel fontSize="sm">Reason</FormLabel>
                            <SearchableSelector value={{
                                label: data.category.toUpperCase(),
                                value: data.category
                            }}
                                onChange={value => {
                                    setData(prev => ({
                                        ...prev,
                                        category: value.value
                                    }));
                                }}
                                size="sm" options={downtimeCategories.map(category => ({
                                    label: category.toUpperCase(),
                                    value: category
                                }))} />
                        </FormControl>
                    </HStack>
                    <FormControl>
                        <FormLabel fontSize="sm">Notes</FormLabel>
                        <Textarea focusBorderColor={"primary"} value={data.note} onChange={e => {
                            setData(prev => ({
                                ...prev,
                                note: e.target.value
                            }));
                        }} placeholder='Any other notes' />
                    </FormControl>
                </VStack>
            </ModalBody>
            <ModalFooter>
                <ConfirmDialog onConfirm={add} heading="Save Downtime" text="Are you sure you want to categorize this downtime?">
                    <Primary size="sm" mr={3} leftIcon={<FaArrowRight />}>Save</Primary>
                </ConfirmDialog>
                <Button size="sm" onClick={onClose} leftIcon={<FaTimes />}>Cancel</Button>
            </ModalFooter>
        </MyModal>
    </> : <></>
}

export default Categorize;