import { FC, useState, Suspense, useMemo, useEffect } from "react";
import {
  useColorMode,
  useColorModeValue,
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Avatar,
  VStack,
  Text,
  Box,
  Skeleton,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Link
} from "@chakra-ui/react";
import {
  BiExitFullscreen,
  BiFullscreen,
  BiMoon,
  BiSun,
  BiChevronDown
} from "react-icons/bi";
import { companyNameAtom, departmentAtom, roleAtom } from "../../../Components/Store/atoms";
import Get from "../../../Components/Store/hooks/Get";
import Logout from "../../../Components/Logout";
import { BsQrCodeScan } from "react-icons/bs";
import MyModal from "../../../Components/micro/MyModal";
import QRCode from "react-qr-code";
import { FaGooglePlay } from "react-icons/fa";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">
export const ColorModeSwitcher: FC<ColorModeSwitcherProps> = props => {
  const { toggleColorMode, colorMode } = useColorMode();
  const SwitchIcon = useColorModeValue(BiMoon, BiSun);
  const { color, hoverBG, hoverColor } = useMemo(() => {
    return {
      color: `${colorMode}.navText`,
      hoverBG: `${colorMode}.hoverBG`,
      hoverColor: `${colorMode}.hoverColor`
    }
  }, [colorMode]);

  return <IconButton
    size="md"
    fontSize="lg"
    variant="ghost"
    color={color}
    _hover={{
      bg: hoverBG,
      color: hoverColor
    }}
    marginLeft="2"
    onClick={toggleColorMode}
    icon={<SwitchIcon />}
    aria-label={`Switch to ${colorMode} mode`}
    {...props}
  />
}

const APP_URL = "https://play.google.com/store/apps/details?id=com.industrialpmr";

export const GetAppQR: FC = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const { color, hoverBG, hoverColor } = useMemo(() => {
    return {
      color: `${colorMode}.navText`,
      hoverBG: `${colorMode}.hoverBG`,
      hoverColor: `${colorMode}.hoverColor`
    }
  }, [colorMode]);

  useEffect(() => {
    const agentData = (navigator as any || {}).userAgentData || {};
    const platform = agentData.platform;
    let timeout: NodeJS.Timeout;
    if (String(platform).toLowerCase() === "android") {
      timeout = setTimeout(onOpen, 2000);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [onOpen]);

  return <>
    <MyModal
      isCentered
      size="lg"
      isOpen={isOpen}
      onClose={onClose}>
      <ModalHeader>Get the industrialpmr app</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <VStack gap={4}>
          <Text textAlign="center">
            Scan this QR code to<br />download the app now
          </Text>
          <Box bgColor="white" p={2} borderRadius="md">
            <QRCode value={APP_URL} />
          </Box>
          <Text textAlign="center">
            Or check it out in the app store
          </Text>
          <Link href={APP_URL} isExternal>
            <Button colorScheme={"orange"} leftIcon={<FaGooglePlay />}>
              <Box my={2} textAlign="left">
                <Text fontSize="x-small" textTransform={"uppercase"} fontWeight={400}>get it on</Text>
                <Text fontSize="lg" textTransform={"capitalize"} fontWeight={500}>google play</Text>
              </Box>
            </Button>
          </Link>
        </VStack>
      </ModalBody>
    </MyModal>
    <IconButton
      size="md"
      fontSize="lg"
      variant="ghost"
      color={color}
      _hover={{
        bg: hoverBG,
        color: hoverColor
      }}
      marginLeft="2"
      onClick={onOpen}
      icon={<BsQrCodeScan />}
      aria-label={`Get industrialpmr App Modal`}
      {...props}
    />
  </>
}

export const AccountButton: FC = () => {
  const { colorMode } = useColorMode();
  const { textColor, bgColor, hoverBgColor, border } = useMemo(() => {
    return {
      textColor: `${colorMode}.accountText`,
      bgColor: `${colorMode}.accountBG`,
      hoverBgColor: `${colorMode}.accountHoverBG`,
      border: `${colorMode}.border`
    }
  }, [colorMode]);
  const companyName = Get(companyNameAtom);
  const role = Get(roleAtom);
  const department = Get(departmentAtom);

  const userState: {
    company_name: string,
    role: "viewer" | "editor",
    department: string[]
  } = useMemo(() => {
    return {
      company_name: companyName || "",
      role: role || "viewer",
      department: department || [""]
    }
  }, [companyName, role, department]) || {
      company_name: "",
      role: "viewer",
      department: [""]
    };

  return <Menu>
    <MenuButton
      py={2}
      transition="all 0.3s"
      _focus={{ boxShadow: 'none' }}
    >
      <HStack ml={2}>
        <Avatar
          bgColor={'gray.500'}
          size={'sm'}
        />
        <VStack
          display={{ base: 'none', md: 'flex' }}
          alignItems="flex-start"
          spacing="1px"
          ml="3">
          {userState.company_name !== "" ? <Text
            color={textColor}
            fontWeight={500}
            maxW="100px"
            textTransform={"capitalize"}
            title={userState.company_name || undefined}
            isTruncated
            fontSize="sm">
            {userState.company_name}
          </Text> : <Skeleton mb={0} h={"20px"} w={"100px"} />}
          {<Text fontSize="xs" color={textColor} textTransform="capitalize">
            {`${userState.role} - ${userState.department.join(", ")}`}
          </Text>}
        </VStack>
        <Box display={{ base: 'none', md: 'flex' }}>
          <BiChevronDown />
        </Box>
      </HStack>
    </MenuButton>
    <MenuList
      borderColor={border}
      bgColor={bgColor}>
      <MenuItem bgColor={bgColor}>
        <HStack
          w="100%"
          justifyContent={"space-between"}
          alignItems="center"
          spacing="1px">
          {userState.company_name !== "" ? <Text
            color={textColor}
            fontWeight={500}
            maxW="200px"
            textTransform={"capitalize"}
            title={userState.company_name || undefined}
            isTruncated
            fontSize="sm">
            {userState.company_name}
          </Text> : <Skeleton mb={0} h={"20px"} w={"100px"} />}
          {<Text fontSize="xs" color={textColor} textTransform="capitalize">
            {`${userState.role} - ${userState.department}`}
          </Text>}
        </HStack>
      </MenuItem>
      <MenuDivider />
      <Suspense fallback={<div>loading...</div>}>
        <Logout Trigger={<MenuItem
          bgColor={bgColor}
          _hover={{
            bgColor: hoverBgColor
          }}>Signout</MenuItem>} />
      </Suspense>
    </MenuList>
  </Menu>
}

export const FullScreenButton: FC = () => {
  const { colorMode } = useColorMode();
  const { color, hoverBG, hoverColor } = useMemo(() => {
    return {
      color: `${colorMode}.navText`,
      hoverBG: `${colorMode}.hoverBG`,
      hoverColor: `${colorMode}.hoverColor`
    }
  }, [colorMode]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    const callback = () => {
      setIsFullScreen(Boolean(document.fullscreenElement));
    }
    document.addEventListener("fullscreenchange", callback);
    return () => {
      document.removeEventListener("fullscreenchange", callback);
    }
  }, []);
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  }

  return <IconButton
    size="md"
    fontSize="lg"
    variant="ghost"
    color={color}
    _hover={{
      bg: hoverBG,
      color: hoverColor
    }}
    onClick={toggleFullScreen}
    marginLeft="2"
    icon={isFullScreen ? <BiExitFullscreen /> : <BiFullscreen />}
    aria-label={`Toggle Fullscreen`}
  />
}