import { FormControl, FormLabel, GridItem, SimpleGrid } from "@chakra-ui/react";
import MyCard from "../../../Components/micro/Card";
import PrimarySelect from "../../../Components/Inputs/PrimarySelect";
import CustomDatePicker from "../../../Components/micro/CustomDatePicker";
import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import { PrimaryBtn } from "../../../Components/Buttons";
import Get from "../../../Components/Store/hooks/Get";
import { EnvListAtom, equipmentsAtom, factoriesAtom, machinesAtom, selectedFactoryAtom, uidAtom } from "../../../Components/Store/atoms";
import MachineSelector from "./MachineSelector";
import { BiRightArrowAlt } from "react-icons/bi";
import { reportParametersSet, ReportParametrsType, ReportSubmitType, ReportTypes } from "./types";
import EquipmentSelector from "./EquipmentSelector";
import EnvironmentSelector from "./EnvironmentSelector";
import { SearchableSelectorOptions } from "../../../Components/Inputs/SearchableSelector";
import UnitSelector from "./UnitSelector";
import { get } from "../../../Components/firebase/api/db";
import FactorySelector from "./FactoryElectricitySelector";
import dayjs from "dayjs";
import CustomMonthPicker from "../../../Components/micro/CustomDatePicker/CustomMonthPicker";
import CustomYearPicker from "../../../Components/micro/CustomDatePicker/CustomYearPicker";
import MaterialSelector from "./MaterialSelector";
import Listen from "../../../Components/Store/hooks/Listen";
import MoldSelector from "./MoldSelector";
import { auth } from "../../../Components/firebase";
import FactoryReportSelector from "./FactoryReportSelector";

const Selector = ({
    onSubmit
}: {
    onSubmit: (reportParameters: ReportSubmitType) => void
}) => {
    const [date, setDate] = useState<DateRange | undefined>(undefined);
    const [reportParameters, setReportParameters] = useState<ReportParametrsType>(reportParametersSet);
    const [type, setType] = useState<ReportTypes>("factory");
    const [units, setUnits] = useState<SearchableSelectorOptions>([]);
    const [allowed, setAllowed] = useState<{
        mainElectricity: boolean
    } | null>(null);
    const machinesRaw = Get(machinesAtom);
    const factories = Get(factoriesAtom);
    const selectedFactory = Listen(selectedFactoryAtom);
    const equipmentsRaw = Get(equipmentsAtom);
    const environmentsRaw = Get(EnvListAtom);
    const uid = Get(uidAtom);
    const machines = useMemo(() => {
        if (!factories || !selectedFactory) return [];
        const mySelectedFactory: any = factories[selectedFactory.value];
        if (machinesRaw === null) return [];
        const machines = Object.keys(machinesRaw).filter(
            (key) =>
                mySelectedFactory === undefined ||
                mySelectedFactory?.machines?.includes(key),
        ).map(key => ({
            label: machinesRaw[key],
            value: key,
        }));
        return [{
            label: "Machines",
            options: machines,
        }];
    }, [machinesRaw, factories, selectedFactory]);
    const equipments = useMemo(() => {
        if (equipmentsRaw === null) return [];
        if (Object.keys(equipmentsRaw).length === 0) return [];
        const eqs = Object.keys(equipmentsRaw).map(key => ({
            label: equipmentsRaw[key],
            value: key,
        }));
        return [{
            label: "Equipments",
            options: eqs,
        }];
    }, [equipmentsRaw]);
    const environments = useMemo(() => {
        if (environmentsRaw === null) return [];
        if (Object.keys(environmentsRaw).length === 0) return [];
        const eqs = Object.keys(environmentsRaw).map(key => ({
            label: environmentsRaw[key],
            value: key,
        }));
        return [{
            label: "Environments",
            options: eqs,
        }];
    }, [environmentsRaw]);
    const isDisabled = useMemo(() => {
        if (allowed === null) return true;
        if (type === "factory") return false;
        else if (type === "main-electricity") {
            if (reportParameters[type].date === undefined) return true;
            else if (reportParameters[type].type === "year" && reportParameters[type].date === "") return true;
            return false;
        } else if (reportParameters[type].option !== undefined) {
            if (reportParameters[type].option === null) return true;
            else if (Array.isArray(reportParameters[type].option) && Object.keys(reportParameters[type].option || {}).length === 0) return true;
        }
        if (reportParameters[type].secondaryOption !== undefined && reportParameters[type].secondaryOption === null) return true;
        return false;
        // eslint-disable-next-line
    }, [reportParameters, type, allowed]);
    useEffect(() => {
        if (uid === null) return;
        const func = async () => {
            const allowed = {
                mainElectricity: false
            };
            const { uid: myUid } = auth.currentUser || {};
            const isSubUser = uid !== myUid;
            const snap = await get("simple_meter/updated");
            allowed.mainElectricity = !!snap.val() && !isSubUser;
            setAllowed(allowed);
        };
        func();
    }, [uid]);
    useEffect(() => {
        if (date === undefined) return;
        setReportParameters(prev => {
            return {
                ...prev,
                [type]: {
                    ...prev[type],
                    date,
                }
            }
        });
    }, [date, type]);
    useEffect(() => {
        if (!factories || !selectedFactory) return;
        if (type === "unit-power" && units.length === 0 && uid !== null) {
            Promise.all([
                get("units"),
                get(`machines`),
                get(`equipments`),
                get(`address`)
            ]).then(([snap, machineSnap, equipmentSnap, addressSnap]) => {
                const address = addressSnap.val();
                const machineNames = Object.fromEntries(Object.entries(machineSnap.val() || {}).map(([key, value]: any) => [key, value.machine_model]));
                const equipmentNames = Object.fromEntries(Object.entries(equipmentSnap.val() || {}).map(([key, value]: any) => [key, value.name]));
                const _val = snap.val();
                if (_val === null) return;
                const mySelectedFactory: any = factories[selectedFactory.value];
                const val = Object.keys(_val).filter(
                    (key) =>
                        mySelectedFactory === undefined ||
                        mySelectedFactory?.units?.includes(+key));
                const unitsKeys = val.map(unit => {
                    const connecteds = [] as string[];
                    if (address[unit]["extras"]) {
                        const humidName = environmentsRaw?.[address[unit]["extras"].dht_humid];
                        if (humidName) connecteds.push(humidName + " (ENV)");
                        else connecteds.push("NONE");
                    } else {
                        const myAddresses = address[unit]["60"];
                        for (const myAddress in myAddresses) {
                            const myAddressData = myAddresses[myAddress].split("&")[0];
                            if (myAddressData === "NONE") continue;
                            else if (myAddressData.includes("eq")) connecteds.push(equipmentNames[myAddressData]);
                            else if (myAddressData.includes("factory")) connecteds.push("MAIN");
                            else connecteds.push(machineNames[myAddressData]);
                        }
                    }
                    // remove duplicates
                    const uniqueConnecteds: string[] = [];
                    new Set(connecteds).forEach(value => uniqueConnecteds.push(value));
                    return {
                        value: unit,
                        label: `UNIT ${String(unit).padStart(2, "0")} - ${uniqueConnecteds.length === 0 ? "NONE" : uniqueConnecteds.join(", ")}`,
                    }
                });
                setUnits(unitsKeys);
            }).catch(console.error);
        }
        // eslint-disable-next-line
    }, [type, uid, factories, selectedFactory]);
    const submit = () => {
        onSubmit({
            type,
            ...reportParameters,
            [type]: {
                ...reportParameters[type],
                generated: new Date().getTime(),
            }
        });
    }
    const changeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // reset date
        const value = e.target.value as ReportTypes;
        setType(value);
    };


    return <MyCard p={3}>
        <SimpleGrid columns={{
            base: 1,
            xl: 6,
        }} gap={5}>
            <FormControl>
                <FormLabel fontSize="sm" opacity={0.9}>Type</FormLabel>
                <PrimarySelect isDisabled={allowed === null} size="sm" borderRadius={"5px"} fontWeight={500} value={type} onChange={changeType}>
                    <optgroup label="Primary Reports">
                        <option value="factory">Factory</option>
                        <option disabled={machines.length === 0} value="machine">Machine</option>
                        <option disabled={equipments.length === 0} value="equipment">Equipment</option>
                        <option disabled={!allowed?.mainElectricity} value="main-electricity">Main Electricity</option>
                    </optgroup>
                    <optgroup label="Secondary Reports">
                        <option value="mold">Mold</option>
                        <option value="unit-power">Unit Power</option>
                        <option value="material">Material Consumption</option>
                        <option disabled={environments.length === 0} value="environment">Environment</option>
                    </optgroup>
                </PrimarySelect>
            </FormControl>
            <GridItem colSpan={{
                base: 1,
                xl: 3,
            }}>{
                    type === "factory" ? <FactoryReportSelector reportParameters={reportParameters} setReportParameters={setReportParameters} />
                        : type === "machine" ? <MachineSelector machines={machines} reportParameters={reportParameters} setReportParameters={setReportParameters} />
                            : type === "equipment" ? <EquipmentSelector equipments={equipments} reportParameters={reportParameters} setReportParameters={setReportParameters} />
                                : type === "environment" ? <EnvironmentSelector environments={environments} reportParameters={reportParameters} setReportParameters={setReportParameters} />
                                    : type === "unit-power" ? <UnitSelector units={units} reportParameters={reportParameters} setReportParameters={setReportParameters} />
                                        : type === "main-electricity" ? <FactorySelector reportParameters={reportParameters} setReportParameters={setReportParameters} />
                                            : type === "material" ? <MaterialSelector reportParameters={reportParameters} setReportParameters={setReportParameters} />
                                                : type === "mold" ? <MoldSelector reportParameters={reportParameters} setReportParameters={setReportParameters} />
                                                        : null
                }
            </GridItem>
            <FormControl>
                <FormLabel fontSize="sm" opacity={0.9}>Duration</FormLabel>
                {type === "main-electricity" ?
                    reportParameters[type].type === "month" ? <CustomMonthPicker
                        set={e => {
                            setReportParameters(prev => {
                                return {
                                    ...prev,
                                    [type]: {
                                        ...prev[type],
                                        date: `${e?.from}|${e?.to}`,
                                    }
                                }
                            });
                        }} />
                        : reportParameters[type].type === "day" ? <CustomDatePicker
                            singleMonth
                            set={e => {
                                setReportParameters(prev => {
                                    return {
                                        ...prev,
                                        [type]: {
                                            ...prev[type],
                                            date: `${dayjs(e?.from).format("YYYY-MM-DD")}|${dayjs(e?.to).format("YYYY-MM-DD")}`,
                                        }
                                    }
                                });
                            }} />
                            : <CustomYearPicker
                                set={e => {
                                    setReportParameters(prev => {
                                        return {
                                            ...prev,
                                            [type]: {
                                                ...prev[type],
                                                date: `${e?.from}|${e?.to}`,
                                            }
                                        }
                                    });
                                }} />
                    : <CustomDatePicker set={setDate} reset={type} />}
            </FormControl>
            <FormControl w="100%">
                <FormLabel fontSize="sm" opacity={0.9}>&nbsp;</FormLabel>
                <PrimaryBtn onClick={submit} rightIcon={<BiRightArrowAlt />} display={"block"} m="auto" size="sm" isDisabled={isDisabled}>Generate</PrimaryBtn>
            </FormControl>
        </SimpleGrid>
    </MyCard>
}

export default Selector