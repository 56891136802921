import dayjs from "./dayjs";

const unitPowerOffAllowedShifts = (
  date: string,
  shift: any,
  sessions: {
    [key: string]: {
      off: number; // unix
      on: number; // unix
    };
  },
  allowedShifts: ("a" | "b" | "c")[],
): number => {
  const shiftData = shift as {
    shifts: number;
    shift_a_start: string; // convert to num using +
    shift_b_start: string; // convert to num using +
    shift_c_start: string; // convert to num using +
  };
  let total = 0;
  const dayStart = dayjs(date).unix();
  for (const session of Object.values(sessions || {})) {
    const start = session.off;
    const end = session.on;
    if (shift.shifts === allowedShifts.length) {
      total += end - start;
      continue;
    }
    if (shift.shifts === 2) {
      if (allowedShifts.includes("a")) {
        if (start < dayStart + +shiftData.shift_b_start) {
          if (end >= dayStart + +shiftData.shift_b_start)
            total += dayStart + +shiftData.shift_b_start - start;
          else total += end - start;
        }
      } else {
        if (end < dayStart + +shiftData.shift_b_start) continue;
        else if (start >= dayStart + +shiftData.shift_b_start) total += end - start;
        else total += end - (dayStart + +shiftData.shift_b_start);
      }
    } else {
      // 3
      if (allowedShifts.length === 2) {
        if (allowedShifts.includes("a") && allowedShifts.includes("b")) {
          if (start >= dayStart + +shiftData.shift_c_start) continue;
          else if (end < dayStart + +shiftData.shift_c_start)
            total += end - start;
          else total += dayStart + +shiftData.shift_c_start - start;
        } else if (allowedShifts.includes("b") && allowedShifts.includes("c")) {
          if (end < dayStart + +shiftData.shift_b_start) continue;
          else if (start < dayStart + +shiftData.shift_b_start)
            total += end - dayStart + +shiftData.shift_b_start;
          else total += end - start;
        } else {
          // a, c
          if (end < dayStart + +shiftData.shift_b_start) total += end - start;
          else if (start >= dayStart + +shiftData.shift_c_start)
            total += end - start;
          else if (
            start >= dayStart + +shiftData.shift_b_start &&
            end < dayStart + +shiftData.shift_c_start
          )
            continue;
          else if (start < dayStart + +shiftData.shift_b_start)
            total += dayStart + +shiftData.shift_b_start - start;
          else total += end - dayStart + +shiftData.shift_c_start;
        }
      } else {
        // 1
        if (allowedShifts.includes("a")) {
            if (start > dayStart + +shiftData.shift_b_start) continue;
            else if (end >= dayStart + +shiftData.shift_b_start) total += dayStart + +shiftData.shift_b_start - start;
            else total += end - start;
        } else if (allowedShifts.includes("b")) {
            if (start >= dayStart + +shiftData.shift_c_start || end < dayStart + +shiftData.shift_b_start) continue;
            else if (start < dayStart + +shiftData.shift_b_start) total += end - dayStart + +shiftData.shift_b_start;
            else if (end >= dayStart + +shiftData.shift_c_start) total += dayStart + +shiftData.shift_c_start - start;
            else total += end - start;
        } else {
            if (end < dayStart + +shiftData.shift_c_start) continue;
            else if (start < dayStart + +shiftData.shift_c_start) total += end - dayStart + +shiftData.shift_c_start;
            else total += end - start;
        }
      }
    }
  }
  return total;
};

export default unitPowerOffAllowedShifts;
