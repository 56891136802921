import { Box, SimpleGrid, Text } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react";
import { DataToSet } from ".."
import Table from "../../../../Components/micro/Table";
import { Duration, Time } from "../Downtime";
import dayjs from "../../../../Components/Functions/dayjs";
import NumberFormatter from "../../../../Components/micro/NumberFormatter";
import { Line, LineConfig } from '@ant-design/plots';
import { viewAll } from "../../../Reports/Viewer/FactoryReport/Machine/MachineBreakdown/PieChart";

const headings = [
    "Time",
    "Act Production",
    "Exp Production",
    "Ontime",
    "Downtime",
    "Efficiency"
];

const HourlyStats = ({
    data,
    hourlyTarget
}: {
    data: DataToSet["hours"],
    hourlyTarget: number
}) => {
    const [animation, setAnimation] = useState<boolean>(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimation(false);
        }, 5000);
        return () => {
            clearTimeout(timeout);
        }
    }, []);
    const [rows, notFound, lineData]: [any[][], boolean, {
        time: string;
        value: number;
        type: 'Act' | 'Exp';
    }[]] = useMemo(() => {
        const myRows: any[][] = [];
        const lineData: {
            time: string;
            value: number;
            type: 'Act' | 'Exp';
        }[] = [];
        let notFound = data.length === 0;
        for (const hour of data) {
            const time = dayjs(`${hour.date} ${hour.time.split("-")[0]}`).unix();
            myRows.push([
                {
                    element: Time,
                    props: {
                        unix: time
                    }
                },
                {
                    element: NumberFormatter,
                    props: {
                        number: hour.production,
                        suffix: "pc(s)"
                    }
                },
                {
                    element: NumberFormatter,
                    props: {
                        number: hourlyTarget,
                        suffix: "pc(s)"
                    }
                },
                {
                    element: Duration,
                    props: {
                        duration: hour.ontime
                    }
                },
                {
                    element: Duration,
                    props: {
                        duration: hour.downtime
                    }
                },
                {
                    element: Text,
                    props: {
                        py: 2,
                        fontSize: "md",
                        fontWeight: 500
                    },
                    children: `${Math.round(hour.ontime / (hour.ontime + hour.downtime) * 100)}%`
                }
            ]);
            lineData.push({
                time: dayjs(`${hour.date} ${hour.time.split("-")[0]}`).format(`Do MMM ha`),
                value: hour.production,
                type: 'Act'
            });
            lineData.push({
                time: dayjs(`${hour.date} ${hour.time.split("-")[0]}`).format(`Do MMM ha`),
                value: hourlyTarget,
                type: 'Exp'
            });
        }
        return [myRows, notFound, lineData];
    }, [data, hourlyTarget]);

    const config: LineConfig = {
        animation,
        data: lineData,
        height: 350,
        xAxis: {
            range: [0, 1],
        },
        yAxis: {
            label: {
                formatter: (v: string) => {
                    return `${v} pc(s)`;
                },
            },
            grid: {
                line: {
                    style: {
                        stroke: 'transparent',
                    },
                },
            },
        },
        smooth: true,
        xField: 'time',
        yField: 'value',
        seriesField: 'type',
    };

    return <SimpleGrid
        alignItems={"center"}
        justifyContent="flex-end"
        pt={5}
        columns={{
            base: 1,
            lg: 2
        }} gap={5}>
        <Line
            legend={{
                position: 'bottom',
            }}
            lineStyle={{
                lineWidth: 3,
            }}
            color={['#ED8936', '#8080805e']}
            tooltip={{
                formatter: (datum: any) => {
                    return {
                        name: datum.type,
                        value: viewAll(datum.value, "production"),
                    };
                }
            }}
            {...config} />
        <Box p={0} maxH="400px" overflow={"auto"}>
            <Table
                headings={headings}
                notFound={notFound}
                rows={rows}
            />
        </Box>
    </SimpleGrid>
}

export default HourlyStats