import { DataToSet } from ".."
import MoldsWorked from "../MoldsWorked"
import MachineHourlyChart from "./Hourly"
import Total from "./Total"

const MachineTotal = ({
    isSingleDay,
    data
}: {
    isSingleDay: boolean,
    data: DataToSet
}) => {
    return <>
        <Total data={data} />
        <MoldsWorked isSingleDay={isSingleDay} molds={data.molds} />
        <MachineHourlyChart hours={data.hours} isSingleDay={isSingleDay} />
    </>
}

export default MachineTotal