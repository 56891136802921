import { CardBody, CardHeader, Table, Tbody, Text, Th, Thead, Tr, useColorMode } from "@chakra-ui/react"
import { useMemo } from "react";
import { DataToSet } from "..";
import MyCard from "../../../../../Components/micro/Card";
import MoldRow from "./MoldRow";

const MoldsWorked = ({
    isSingleDay,
    molds
}: {
    isSingleDay: boolean,
    molds: DataToSet["molds"]
}) => {
    const { colorMode } = useColorMode();
    const { border, headBG, headColor } = useMemo(() => {
        return {
            border: `${colorMode}.border`,
            headBG: `${colorMode}.tableHeaderBG`,
            headColor: `${colorMode}.tableHeaderColor`
        }
    }, [colorMode]);

    const headings: string[] = [
        "Mold",
        "Production Stats",
        "Consumption Stats",
        "Timings",
        "Operation"
    ];

    return <MyCard
        w="100%"
        h="100%"
        noPadding>
        <CardHeader
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom="1px solid"
            borderColor={border}
            py={4}>
            <Text
                fontSize="md"
                fontWeight={500}>Molds Worked</Text>
        </CardHeader>
        <CardBody p={0}>
            <Table variant='simple' overflowX={{
                base: "scroll",
                midLgXl: "hidden"
            }} display={{
                base: "block",
                midLgXl: "table"
            }}>
                <Thead
                    color={headColor}
                    bgColor={headBG}>
                    <Tr>
                        {headings.map((heading, i) => <Th
                            key={i}
                            color={headColor}>{heading}</Th>)}
                    </Tr>
                </Thead>
                <Tbody fontSize={14}>
                    {Object.entries(molds).map(([moldName, mold]) => <MoldRow moldName={moldName} key={moldName} mold={mold} />)}
                </Tbody>
            </Table>
        </CardBody>
    </MyCard>
}

export default MoldsWorked;