import { FormControl, FormLabel, GridItem, SimpleGrid } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { get } from "../../../Components/firebase/api/db";
import PrimarySelect from "../../../Components/Inputs/PrimarySelect"
import SearchableSelector, { SearchableSelectorOptions } from "../../../Components/Inputs/SearchableSelector"
import { machinesAtom } from "../../../Components/Store/atoms";
import Get from "../../../Components/Store/hooks/Get";
import type { Option, ReportParametrsType } from "./types";

const MaterialSelector = ({
    setReportParameters,
    reportParameters
}: {
    setReportParameters?: (params: ReportParametrsType) => void,
    reportParameters?: ReportParametrsType
}) => {
    const [reportByMachine, setReportByMachine] = useState(true);
    const [materials, setMaterials] = useState<SearchableSelectorOptions>([]);
    const [selected, setSelected] = useState<null | Option | Option[]>(null);
    const _machines = Get(machinesAtom);
    const machines = useMemo(() => {
        if (!_machines) return [];
        return Object.entries(_machines).map(([id, name]) => ({
            label: name as string,
            value: id
        }))
    }, [_machines]);

    useEffect(() => {
        setSelected(null);
        if (reportByMachine || materials.length !== 0) return;
        const func = async () => {
            const snap = await get("materials/inventory");
            const data = Object.keys(snap.val() || {}).map(mat => {
                return {
                    label: mat.toUpperCase(),
                    value: mat.toLowerCase()
                }
            });
            setMaterials(data);
        };
        func();
    }, [reportByMachine, materials])

    useEffect(() => {
        if (!setReportParameters || !reportParameters) return;
        setReportParameters({
            ...reportParameters,
            material: {
                ...reportParameters.material,
                option: reportByMachine ? {
                    value: "machine",
                    label: "Machine"
                } : {
                    value: "material",
                    label: "Material"
                },
                secondaryOption: selected
            }
        });
        // eslint-disable-next-line
    }, [reportByMachine, selected])

    return <SimpleGrid columns={3} gap={5}>
        <FormControl w="100%">
            <FormLabel fontSize="sm" opacity={0.9}>Report by</FormLabel>
            <PrimarySelect size="sm" borderRadius={"5px"} fontWeight={500} value={reportByMachine ? "machine" : "material"}
                isDisabled={machines.length === 0}
                onChange={e => {
                    setSelected(null);
                    setReportByMachine(e.target.value === "machine");
                }}>
                <option value="machine">Machine</option>
                <option value="material">Material</option>
            </PrimarySelect>
        </FormControl>
        <GridItem colSpan={2}>
            <FormControl w="100%">
                <FormLabel fontSize="sm" opacity={0.9}>{reportByMachine ? "Machine" : "Material"}</FormLabel>
                <SearchableSelector
                    onChange={setSelected}
                    value={selected}
                    isDisabled={reportByMachine ? machines.length === 0 : materials.length === 0}
                    options={reportByMachine ? machines : materials}
                    className="border-radius-5"
                    size="sm" />
            </FormControl>
        </GridItem>
    </SimpleGrid>
}

export default MaterialSelector