import dayjs from "../Functions/dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { Text, TextProps, Tooltip } from "@chakra-ui/react";
dayjs.extend(duration);
dayjs.extend(relativeTime);

interface SinceTimeProps extends TextProps {
    since: number | string,
    tooltipPrefix?: string,
    textPrefix?: string,
    textSuffix?: string
}
const SinceTime = ({
    since,
    tooltipPrefix = "",
    textPrefix = "since: ",
    textSuffix = "",
    ...props
}: SinceTimeProps): JSX.Element => {
    let mySince: dayjs.Dayjs;
    if (typeof since === "string") mySince = dayjs(since);
    else mySince = dayjs.unix(since);
    const now = dayjs();
    const element = dayjs.duration(now.diff(mySince)).humanize();

    return <Text
        {...props}>
        {textPrefix}
        <strong>
            <Tooltip
                label={tooltipPrefix + mySince.format("hh:mm:ss A DD MMM, YYYY")}
                aria-label="A tooltip"
                placement="top"
                hasArrow
                bg="gray.700"
                color="white"
                fontSize="xs"
                fontWeight={400}
                borderRadius="md"
                boxShadow="md"
                p={2}
                m={0}>
                {element}
            </Tooltip>
        </strong>
        {textSuffix}
    </Text>
}

export default SinceTime