import store from "..";
import { useEffect, useState } from "react";
import { Atom } from "jotai";

// : <Value>(atom: Atom<Value>) => Value
const Get = <Value>(atom: Atom<Value>): Value => {
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    const value = store.get(atom);
    if (value !== null) setValue(value);
    else {
      const unsub = store.sub(atom, () => {
        const value = store.get(atom);
        if (value === null) return;
        setValue(value);
        unsub();
      });
    }
  }, [atom]);

  return value;
};

export default Get;
