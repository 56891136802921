type downtimeCategoriesType =
  | "uncategorized"
  | "machine problem"
  | "machine maintenance"
  | "material"
  | "mold change"
  | "part change"
  | "other";

const downtimeCategories: downtimeCategoriesType[] = [
  "uncategorized",
  "machine problem",
  "machine maintenance",
  "material",
  "mold change",
  "part change",
  "other",
];

export default downtimeCategories;
export type { downtimeCategoriesType };
