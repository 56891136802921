import { IconType } from "react-icons";
import { get, remove } from "../../../Components/firebase/api/db";
import { BiUnlink } from "react-icons/bi";
import { BsBattery } from "react-icons/bs";
import store from "../../../Components/Store";
import { machinesAtom } from "../../../Components/Store/atoms";
import { RiDonutChartFill } from "react-icons/ri";
import { FiPieChart } from "react-icons/fi";

interface NotificationProp {
  id: number;
  title: string;
  description: string;
  icon: IconType;
  time?: number;
  loader?: boolean;
  loaderVal?: number;
  ack?: () => void;
}
const fetchExtErrors = (): Promise<NotificationProp[]> => {
  return new Promise((resolve, reject) => {
    get("errors/extention-error")
      .then(async (snap) => {
        if (!snap.exists()) resolve([]);
        else {
          const data = snap.val();
          const errors = [];
          for await (const [unit, addressParent] of Object.entries(
            data || {},
          )) {
            for await (const [address, timestamp] of Object.entries(
              addressParent || {},
            )) {
              errors.push({
                id: timestamp,
                title: `Hardware error on UNIT#${String(unit).padStart(
                  2,
                  "0",
                )}`,
                description: `CPU unit ${String(unit).padStart(
                  2,
                  "0",
                )} is unable to communicate with address ${address}.\nPlease contact support.`,
                icon: BiUnlink,
                time: timestamp,
              });
            }
          }
          resolve(errors);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchRTCErrors = (): Promise<NotificationProp[]> => {
  return new Promise((resolve, reject) => {
    get("errors/rtc_error")
      .then(async (snap) => {
        if (!snap.exists()) resolve([]);
        else {
          const data = snap.val();
          const errors = Object.entries(data).map(([unit, timestamp]) => {
            return {
              id: timestamp as number,
              title: `RTC Battery low on UNIT#${String(unit).padStart(2, "0")}`,
              description: `RTC Battery on CPU unit ${String(unit).padStart(
                2,
                "0",
              )} is low.\nPlease contact support for replacement.`,
              icon: BsBattery,
              time: timestamp as number,
              ack: () => {
                remove(`errors/rtc_error/${unit}`)
              }
            };
          });
          resolve(errors);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchMoldLifeError = (): Promise<{
  errors: NotificationProp[];
  alerts: NotificationProp[];
}> => {
  return new Promise((resolve, reject) => {
    const machines = store.get(machinesAtom);
    if (!machines) return;
    let i = 0;
    get("errors/mold-life")
      .then(async (snap) => {
        if (!snap.exists())
          resolve({
            alerts: [],
            errors: [],
          });
        else {
          const data: {
            [machineID: string]: {
              [moldID: string]: {
                consumed: number;
                life: number;
              };
            };
          } = snap.val();
          const toReturn = {
            errors: [] as NotificationProp[],
            alerts: [] as NotificationProp[],
          };
          for (const mID in data) {
            const machineName = machines[mID] || mID;
            for (const moldID in data[mID]) {
              const { consumed, life } = data[mID][moldID];
              const usage = Math.round((consumed / life) * 100);
              if (usage > 100)
                toReturn.errors.push({
                  id: i,
                  title: `Mold life exceeded on ${machineName}`,
                  description: `Mold ${moldID} on ${machineName} has exceeded its life (${usage.toFixed(0)}% used).\nPlease replace mold or reset usage.`,
                  icon: RiDonutChartFill,
                });
              else
                toReturn.alerts.push({
                  id: i,
                  title: `Mold life warning ${machineName}`,
                  description: `Mold ${moldID} on ${machineName} is nearing its life (${usage.toFixed(0)}% used).\nPlease replace mold or reset usage.`,
                  icon: RiDonutChartFill,
                });
              i++;
            }
          }
          resolve(toReturn);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const fetchTargetReachAlert = (): Promise<{
  errors: NotificationProp[];
  alerts: NotificationProp[];
}> => {
  return new Promise((resolve, reject) => {
    const machines = store.get(machinesAtom);
    if (!machines) return;
    let i = 0;
    get("errors/target-alerts")
      .then(async (snap) => {
        if (!snap.exists())
          resolve({
            alerts: [],
            errors: [],
          });
        else {
          const data: {
            [machineID: string]: {
              [moldID: string]: {
                milestone: 50 | 75 | 100
              };
            };
          } = snap.val();
          const toReturn = {
            errors: [] as NotificationProp[],
            alerts: [] as NotificationProp[],
          };
          for (const mID in data) {
            const machineName = machines[mID] || mID;
            for (const moldID in data[mID]) {
              const { milestone } = data[mID][moldID];
              toReturn.alerts.push({
                id: i,
                title: `Production target milestone reached on ${machineName}`,
                description: `Mold ${moldID} on ${machineName} reached ${milestone}% of its target.`,
                icon: milestone === 100 ? RiDonutChartFill : FiPieChart,
                ack: () => {
                  remove(`errors/target-alerts/${mID}/${moldID}`)
                }
              });
              i++;
            }
          }
          resolve(toReturn);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
interface returnProps {
  errors: NotificationProp[];
  alerts: NotificationProp[];
}
const fetch = (): Promise<returnProps> => {
  return new Promise((resolve, reject) => {
    Promise.all([fetchExtErrors(), fetchRTCErrors(), fetchMoldLifeError(), fetchTargetReachAlert()])
      .then((values) => {
        const [errors, alerts, moldErrors, targetAlerts] = values;
        errors.push(...moldErrors.errors);
        alerts.push(...moldErrors.alerts);
        alerts.push(...targetAlerts.alerts);
        resolve({ errors, alerts });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default fetch;
