import { auth } from "../firebase";
import store from "../Store";
import { roleAtom, uidAtom } from "../Store/atoms";

const REST_SERVER_URL = process.env[`REACT_APP_REST_SERVER_URL_${process.env.NODE_ENV.toUpperCase()}`];
const sendDataRest = async (
  unit: number,
  params: [string, string, object, number | undefined],
): Promise<number> => {
  const uid = store.get(uidAtom);
  const role = store.get(roleAtom);
  const { uid: myUID } = auth.currentUser || {};
  const hasAccess = uid === myUID || role === "editor";
  if (!hasAccess) return 401;
  if (!uid) return 0;
  const headers = {
    "Content-Type": "application/json",
    uid,
    unit: String(unit),
  };
  const payload = {
    target: params[0],
    id: params[1],
    data: params[2],
    factory: isNaN(params[3] as number) ? -1 : params[3],
  };
  const URL = REST_SERVER_URL + "/update-data"
  const response = await fetch(URL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload),
  });
  return response.status;
};

export default sendDataRest;
export { REST_SERVER_URL };