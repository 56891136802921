import { Box, Center, Text, useColorMode, VStack } from "@chakra-ui/react";
import { DataToSet } from "..";
import { useMemo } from "react";
import { pulse } from "../../../Machines/Machine/MachineDetails";
import NumberFormatter from "../../../../Components/micro/NumberFormatter";
import MinifiedNumberFormatter from "../../../../Components/micro/MinifiedNumberFormatter";
import { RadialBar, RadialBarConfig } from "@ant-design/plots";
import { viewAll } from "../../../Reports/Viewer/FactoryReport/Machine/MachineBreakdown/PieChart";
import IsScreenSmall from "../../../../Components/Store/hooks/IsScreenSmall";

const InnerLabel = ({
    data,
    isSmall
}: {
    data: DataToSet["progress"],
    isSmall?: boolean
}) => {
    const { colorMode } = useColorMode();
    const { subText } = useMemo(() => ({
        subText: `${colorMode}.subText`,
    }), [colorMode]);

    return <VStack pos="absolute" textAlign={"center"}>
        <Text fontSize={isSmall ? "sm" : "md"} fontWeight={600} lineHeight={1.2}>
            {/* shouldve made */}
            <MinifiedNumberFormatter number={data.shouldbe} />
            <Text color={subText} textTransform="uppercase" fontSize={isSmall ? "xs" : "sm"}>pc(s) should have made</Text>
        </Text>
        <Text fontSize={isSmall ? "md" : "4xl"} fontWeight={600} lineHeight={1.2}>
            <NumberFormatter number={data.current} />
            <Text color={subText} textTransform="uppercase" fontSize={isSmall ? "xs" : "sm"}>pc(s) made of <span style={{
                fontWeight: 700
            }}><MinifiedNumberFormatter number={data.total} suffix="pc(s)" /></span></Text>
        </Text>
    </VStack>
}

const ProgressSection = ({
    data,
    status
}: {
    data: DataToSet["progress"],
    status: DataToSet["status"]
}) => {
    const isBelow1400 = IsScreenSmall(1400);

    const config: RadialBarConfig = {
        data: [{
            name: "Actual",
            count: data.current
        }, {
            name: "Should be",
            count: data.shouldbe
        }, {
            name: "Target",
            count: data.total
        }],
        animation: false,
        xField: 'name',
        yField: 'count',
        maxAngle: 300,
        radius: isBelow1400 ? 0.9 : 1,
        innerRadius: isBelow1400 ? 0.6 : 0.7,
        tooltip: {
            formatter: (datum) => {
                return {
                    name: datum.name === "Actual" ? "Actual pc(s)" : datum.name === "Target" ? "Target pc(s)" : "Should be pc(s)",
                    value: viewAll(datum.count, "production"),
                };
            },
        },
        colorField: 'name',
        color: ({ name }) => {
            if (name === "Actual") {
                return data.track > 0 ? "#0d9f8b" : data.track < -1800 ? "#e95e44" : "#FDD36A"
            } else if (name === "Should be") {
                return "#FDD36A40";
            } return status ? "#0d9f8b" : "#e95e44";
        },
        barStyle: {
            lineCap: 'round',
        },
    };

    return <Center>
        <Box
            position={"absolute"}
            animation={status.status === "ON" ? `${pulse} 2s infinite` : undefined}
            w={isBelow1400 ? "300px" : "380px"}
            h={isBelow1400 ? "300px" : "380px"}
            borderRadius={"full"}></Box>
        <RadialBar {...config} />
        <InnerLabel data={data} />
    </Center>
};

export { InnerLabel };
export default ProgressSection;