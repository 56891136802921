import { Box, CardBody, CardFooter, CardHeader, Center, GridItem, Heading, Icon, Progress, SimpleGrid, Text, useColorMode, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Machine } from ".."
import MyCard from "../../../Components/micro/Card";
import MyTooltip from "../../../Components/micro/MyTooltip";
import dayjs from "../../../Components/Functions/dayjs";
import minifiedSecFormatter from "../../../Components/Functions/formatters/minifiedSecFormatter";
import { BiTimeFive, BiUser } from "react-icons/bi";
import { useMemo } from "react";
import secFormatter from "../../../Components/Functions/formatters/secFormatter";
import { Column, ColumnConfig, RadialBar, RadialBarConfig } from '@ant-design/plots';
import { viewAll } from "../../Reports/Viewer/FactoryReport/Machine/MachineBreakdown/PieChart";
import { InnerLabel } from "../Target/CurrentStats/ProgressSection";

const ProductionCard = ({
    machine
}: {
    machine: Machine
}) => {
    const { colorMode } = useColorMode();
    const { text, subText, border } = useMemo(() => ({
        text: `${colorMode}.text`,
        subText: `${colorMode}.subText`,
        border: `${colorMode}.border`
    }), [colorMode]);
    const headingText = "whiteAlpha.900";
    const hourlyData: {
        time: string,
        value: number,
        type: string
    }[] = useMemo(() => {
        const data: {
            time: string,
            value: number,
            type: string,
        }[] = [];
        for (const key in machine.hours) {
            const hour = machine.hours[key];
            const efficiency = +(
                // ratio between ontime and ontime + idle time
                (
                    hour.ontime && hour.ontime + hour.downtime
                        ? (hour.ontime /
                            (hour.ontime + hour.downtime)) *
                        100
                        : 0
                ).toFixed(2));
            data.push({
                time: `${hour.date}|${hour.time}`,
                value: hour.production,
                type: efficiency >= 50 ? "GOOD" : "BAD"
            });
        }
        return data;
    }, [machine]);

    const efficiency = useMemo(() => {
        if (machine.progress.total === 0) return 0;
        if (machine.target.due === null) return 100;
        const start = machine.target.started;
        const now = dayjs().unix() - start;
        const dueTime = machine.target.due === null ? dayjs(machine.target.due).unix() : machine.timing.projectedEndTime - start;
        // if (now > dueTime) return 0;
        return (now / dueTime) * 100;
    }, [machine]);

    const config: ColumnConfig = {
        data: hourlyData,
        xField: 'time',
        yField: 'value',
        seriesField: 'type',
        columnStyle: {
            radius: [10, 10, 0, 0],
        },
        xAxis: {
            label: null
        },
        yAxis: {
            label: null,
            tickCount: 0
        },
        label: false,
        animation: false,
        legend: false,
        color: (datum) => {
            if (datum.type === "BAD") return "#e95e44";
            return "#0d9f8b";
        },
        tooltip: {
            formatter: datum => {
                return {
                    name: datum.time.split("|")[1],
                    value: viewAll(datum.value, "production")
                }
            },
            title: v => v.split("|")[0]
        }
    };

    const radialConfig: RadialBarConfig = {
        data: [{
            name: "Actual",
            count: machine.progress.current
        }, {
            name: "Should be",
            count: machine.progress.shouldbe
        }, {
            name: "Target",
            count: machine.progress.total
        }],
        animation: false,
        xField: 'name',
        yField: 'count',
        maxAngle: 300,
        radius: 0.7,
        innerRadius: 0.75,
        tooltip: {
            formatter: (datum) => {
                return {
                    name: datum.name === "Actual" ? "Actual pc(s)" : datum.name === "Target" ? "Target pc(s)" : "Should be pc(s)",
                    value: viewAll(datum.count, "production"),
                };
            },
        },
        colorField: 'name',
        color: ({ name }) => {
            if (name === "Actual") {
                return machine.progress.track > 0 ? "#0d9f8b" : machine.progress.track < -1800 ? "#e95e44" : "#FDD36A"
            } else if (name === "Should be") {
                return "#FDD36A40";
            } return machine.status ? "#0d9f8b" : "#e95e44";
        },
        barStyle: {
            lineCap: 'round',
        },
    };

    return <Box
        maxW={{
            base: "100%",
            sm: 350
        }}
        as={Link}
        to={`target/${machine.id}`}>
        <MyCard
            _hover={{
                transform: "translateY(-3px)",
                boxShadow: "xl"
            }}
            transition={"all 0.2s ease-in-out"}
            p={0}>
            <CardHeader
                h="70px"
                borderTopRadius={"md"}
                bgColor={
                    machine.status.status === "ON" ? "green" :
                        machine.status.status === "OFF" ? "red" :
                            machine.status.status === "IDLE" ? "orange" :
                                machine.status.status === "NA" ? "gray" :
                                    undefined
                }
                borderBottom={`1px solid`}
                borderBottomColor={border}
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                py={3}>
                <Heading textAlign={"center"} color={headingText} size={"md"}>{machine.name}</Heading>
                <Text textAlign={"center"} fontSize={"xs"} color={headingText} fontWeight={600}>
                    <MyTooltip label={dayjs.unix(machine.status.since).format("DD-MM-YYYY HH:mm:ss")}>
                        {minifiedSecFormatter(dayjs().unix() - machine.status.since)}
                    </MyTooltip>
                    {` - ${machine.status.status}`}
                </Text>
            </CardHeader>
            <CardBody
                p={0}
                as={VStack}>
                <Center flexDir="column">
                    <Center>
                        <RadialBar {...radialConfig} />
                        <InnerLabel data={machine.progress} />
                    </Center>
                    <Text fontSize={"sm"} fontWeight={600} textTransform="uppercase">
                        <MyTooltip label={`${dayjs.unix(machine.mold.since).fromNow()} | ${dayjs.unix(machine.mold.since).format("DD-MM-YYYY HH:mm:ss")}`}>
                            {`mold: ${machine.mold.name} (${machine.mold.cycleTime}s)`}
                        </MyTooltip>
                    </Text>
                </Center>
                <SimpleGrid columns={2} w="100%" justifyContent={"flex-start"}>
                    <GridItem colSpan={2} w="100%" borderY={"1px solid"} borderColor={border} py={2} px={1}>
                        <Box
                            gap={3}
                            display="flex"
                            alignItems={"center"}
                            justifyContent="center"
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            <Text fontWeight={500} opacity={0.9}>
                                Prog:
                            </Text>
                            <Progress
                                w="200px"
                                size="md"
                                borderRightRadius={'md'}
                                colorScheme={machine.progress.track > 0 ? "green" : machine.progress.track < -1800 ? "red" : "orange"}
                                value={
                                    (efficiency) || 0
                                }
                            />
                            <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                {efficiency.toFixed(0)}%
                            </Text>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={2} w="100%" borderY={"1px solid"} borderColor={border} py={2}>
                        <Column height={100} {...config} />
                    </GridItem>
                    <GridItem colSpan={2} w="100%" borderY={"1px solid"} borderColor={border} py={2}>
                        <Box
                            gap={3}
                            display="flex"
                            alignItems={"center"}
                            justifyContent="center"
                            textTransform={"uppercase"}
                            color={subText}
                            fontSize={"sm"}>
                            <Text fontWeight={500} opacity={0.9}>
                                OEE:
                            </Text>
                            <Progress
                                w="200px"
                                size="md"
                                borderRightRadius={'md'}
                                colorScheme={machine.OEE >= 75 ? "green" :
                                    machine.OEE >= 50 ? "orange" :
                                        "red"}
                                value={
                                    (machine.OEE) || 0
                                }
                            />
                            <Text
                                textTransform={"lowercase"}
                                as={"span"}
                                ml={2}
                                fontWeight={"bold"}
                                color={text}>
                                {machine.OEE}%
                            </Text>
                        </Box>
                    </GridItem>
                    <Box
                        borderRight={"1px solid"} borderBottom={"1px solid"} borderColor={border}
                        p={2}
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="flex-start"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        <Text fontWeight={500} opacity={0.9}>
                            ontime:
                        </Text>
                        <Text
                            textTransform={"lowercase"}
                            as={"span"}
                            ml={2}
                            fontWeight={"bold"}
                            color={text}>
                            <MyTooltip
                                label={secFormatter(machine.timing.ontime)}>
                                {minifiedSecFormatter(machine.timing.ontime)}
                            </MyTooltip>
                        </Text>
                    </Box>
                    <Box
                        borderBottom={"1px solid"} borderColor={border}
                        p={2}
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="flex-start"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        <Text fontWeight={500} opacity={0.9}>
                            downtime:
                        </Text>
                        <Text
                            textTransform={"lowercase"}
                            as={"span"}
                            ml={2}
                            fontWeight={"bold"}
                            color={text}>
                            <MyTooltip
                                label={secFormatter(machine.timing.downtime)}>
                                {minifiedSecFormatter(machine.timing.downtime)}
                            </MyTooltip>
                        </Text>
                    </Box>
                    <Box
                        p={2}
                        as={GridItem}
                        colSpan={2}
                        gap={3}
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        textTransform={"uppercase"}
                        color={subText}
                        fontSize={"sm"}>
                        <Text fontWeight={500} opacity={0.9}>
                            projected completion:
                        </Text>
                        <Text
                            textTransform={"lowercase"}
                            as={"span"}
                            ml={2}
                            fontWeight={"bold"}
                            color={text}>
                            {dayjs.unix(machine.timing.projectedEndTime).isBefore(dayjs()) ?
                                "Anytime now"
                                : <MyTooltip
                                    label={
                                        dayjs.unix(machine.timing.projectedEndTime).format("DD-MM-YYYY HH:mm:ss")
                                    }>
                                    {`in ${minifiedSecFormatter(Math.abs(dayjs().diff(dayjs.unix(machine.timing.projectedEndTime), "second")))}`}
                                </MyTooltip>}
                        </Text>
                    </Box>
                </SimpleGrid>
            </CardBody>
            <CardFooter
                borderTop={`1px solid`}
                borderTopColor={border}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                px={4}>
                <Text
                    display={"flex"}
                    alignItems={"center"}
                    textAlign={"center"}
                    fontSize={"xs"}
                    color={text}
                    fontWeight={600}>
                    <Icon
                        fontSize={"md"}
                        color={text}
                        mr={1}
                        as={BiUser} />
                    <MyTooltip label={`Shift Timing: ${machine.operator.shift}`}>
                        {machine.operator.name}
                    </MyTooltip>
                </Text>
                <Text
                    display={"flex"}
                    alignItems={"center"}
                    textAlign={"center"}
                    fontSize={"xs"}
                    color={text}
                    fontWeight={600}>
                    <Icon
                        fontSize={"md"}
                        color={text}
                        mr={1}
                        as={BiTimeFive} />
                    <MyTooltip label={dayjs.unix(machine.updated).format("DD-MM-YYYY HH:mm:ss")}>
                        {dayjs.unix(machine.updated).fromNow()}
                    </MyTooltip>
                </Text>
            </CardFooter>
        </MyCard>
    </Box>
}

export default ProductionCard