import { CardBody, CardHeader, Text, useColorMode } from "@chakra-ui/react"
import { useMemo } from "react";
import MyCard from "../../../Components/micro/Card";
import MachineHourlyTree from "../../../Components/micro/MachineHourlyTree";
import { SortedHourTree } from "./types";

const HourlyBreakdown = ({
    notFound,
    data
}: {
    notFound: boolean,
    data: SortedHourTree[]
}) => {
    const { colorMode } = useColorMode();
    const { border } = useMemo(() => {
        return {
            border: `${colorMode}.border`,
        }
    }, [colorMode]);

    return <MyCard
        w="100%"
        h="100%"
        noPadding>
        <CardHeader
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom="1px solid"
            borderColor={border}
            py={4}>
            <Text
                fontSize="md"
                fontWeight={500}>Hourly Details</Text>
        </CardHeader>
        <CardBody px={0} py={2} maxH="500px" overflow="auto">
            <MachineHourlyTree data={data} notFound={notFound} />
        </CardBody>
    </MyCard>
}

export default HourlyBreakdown;