import { get } from "../../../Components/firebase/api/db";
import { userType } from "./types";
import dayjs from "../../../Components/Functions/dayjs";

const fetch = async (setUsers: Function) => {
  const ref = await get(`sub-users`);
  const users = ref.val() || {};
  const myUsers: userType[] = Object.entries(users).map(([uid, user]: any) => {
    const { department, disabled, email, lastLogin, name, role } = user;
    return {
      uid: uid as string,
      email: email as string,
      name: name as string,
      lastLogin: lastLogin
        ? dayjs(lastLogin).format("YYYY-MM-DD HH:mm:ss")
        : "Never",
      role: role as userType["role"],
      department: department as string[],
      disabled: disabled as boolean,
    };
  });
  setUsers(myUsers);
  return;
};

export default fetch;
