import { ColorProps, Flex, ModalBody, ModalCloseButton, ModalHeader, Text, useDisclosure } from "@chakra-ui/react";
import { cloneElement, useEffect, useState } from "react";
import MyModal from "../../../Components/micro/MyModal";
import { Box, Icon, useColorModeValue } from '@chakra-ui/react';
import BackdropLoader from "../../../Components/Loaders/BackdropLoader";
import { activityType, DeviveInfo } from "../../../Components/micro/logger";
import { get, query } from "../../../Components/firebase/api/db";
import { IconType } from "react-icons";
import NotFound from "../../../Components/micro/NotFound";
import { FiActivity, FiEdit3, FiPlusSquare, FiUserMinus, FiUserPlus } from "react-icons/fi";
import dayjs from "../../../Components/Functions/dayjs";

type DBLogs = {
    activity: activityType,
    timestamp: number,
    deviceInfo: DeviveInfo,
    data: any
};
interface DataToSet {
    name: string,
    totalActivity: number,
    logs: {
        logo: IconType,
        title: string,
        date: string,
        color: ColorProps["color"],
    }[]
};
const ActivityModal = ({
    uid,
    children
}: {
    uid: "all" | string,
    children: JSX.Element
}) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const btn = cloneElement(children as JSX.Element, { ...children.props, onClick: onOpen });
    const [data, setData] = useState<DataToSet | null>(null);

    useEffect(() => {
        if (!isOpen) return;
        setData(null);
        const func = async () => {
            if (uid === "all") {
                const [usersSnap, snap] = await Promise.all([
                    get("sub-users"),
                    query("logs")
                ]);
                const users = Object.fromEntries(Object.entries(usersSnap.val() || {}).map(([uid, data]) => ([uid as string, `${(data as any).name} (${(data as any).email})`]))) as {
                    [uid: string]: string
                };
                const data = snap.val() as {
                    [uid: string]: {
                        [key: string]: DBLogs
                    }
                };
                const dataToSet: DataToSet = {
                    name: "All",
                    totalActivity: 0,
                    logs: []
                };
                for (const uid in data) {
                    const userName = users[uid] || "Master User";
                    const userLogs = data[uid];
                    const logs: DataToSet["logs"] = [];
                    for (const key in userLogs) {
                        dataToSet.totalActivity++;
                        const activity = userLogs[key];
                        dataToSet.logs.push(activitySwitch(activity.activity, activity.timestamp, userName));
                    }
                    dataToSet.logs.push(...logs);
                    dataToSet.logs.sort((a, b) => {
                        return dayjs(b.date).unix() - dayjs(a.date).unix();
                    });
                };
                setData(dataToSet);
            } else {
                const [userSnap, snap] = await Promise.all([
                    get(`sub-users/${uid}`),
                    get(`logs/${uid}`)
                ]);
                const user = userSnap.val() as {
                    name: string,
                    email: string
                };
                const data = snap.val() as {
                    [key: string]: DBLogs
                };
                const dataToSet: DataToSet = {
                    name: `${user.name} (${user.email})`,
                    totalActivity: 0,
                    logs: []
                };
                for (const key in data) {
                    dataToSet.totalActivity++;
                    const activity = data[key];
                    dataToSet.logs.push(activitySwitch(activity.activity, activity.timestamp, user.name));
                }
                dataToSet.logs.reverse();
                setData(dataToSet);
            }
        };
        try {
            func();
        } catch (err) {
            console.error(err);
            onClose();
        }
        // eslint-disable-next-line
    }, [uid, isOpen]);

    return data === null && isOpen ? <BackdropLoader text={"Loading logs"} />
        : <>
            {btn}
            {data !== null && <MyModal
                size={"xl"}
                isOpen={isOpen}
                onClose={onClose}>
                <ModalHeader>
                    <Flex direction="column">
                        <Text fontSize="lg" fontWeight="bold" pb=".5rem">
                            {data.name} user activity
                        </Text>
                        <Text fontSize="sm" opacity={0.8} fontWeight="normal">
                            <Text fontWeight="bold" as="span" color="primary">
                                {data.totalActivity}
                            </Text>{" "}
                            activities
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody ps="26px" pe="0px" mb="31px" position="relative">
                    <Flex direction="column">
                        {data.logs.length ? data.logs.map((row, index, arr) => {
                            return (
                                <TimelineRow
                                    key={index}
                                    logo={row.logo}
                                    title={row.title}
                                    date={row.date}
                                    color={row.color}
                                    index={index}
                                    arrLength={arr.length}
                                />
                            )
                        }) : <NotFound icon={FiActivity} text="No Activity Found." />}
                    </Flex>
                </ModalBody>
            </MyModal>
            }
        </>
}

function TimelineRow({
    logo,
    title,
    date,
    color,
    index,
    arrLength
}: {
    logo: IconType,
    title: string,
    date: string,
    color: ColorProps["color"],
    index: number,
    arrLength: number
}) {
    const textColor = useColorModeValue('gray.700', 'white.300');

    return (
        <Flex alignItems='flex-start' minH='78px' justifyContent='start' mb='5px'>
            <Flex direction='column' h='100%'>
                <Icon
                    as={logo}
                    color={color}
                    h={'35px'}
                    w={'35px'}
                    pe='6px'
                    zIndex='1'
                    position='relative'
                    right={document.documentElement.dir === 'rtl' ? '-8px' : ''}
                    left={document.documentElement.dir === 'rtl' ? '' : '-8px'}
                />
                <Box w='2px' bg='whiteAlpha.400' h={index === arrLength - 1 ? '15px' : '50px'} />
            </Flex>
            <Flex mt={2} direction='column' justifyContent='flex-start' h='100%'>
                <Text fontSize="md" color={textColor} fontWeight={500}>
                    {title}
                </Text>
                <Text fontSize="sm" color='gray.400' fontWeight='normal'>
                    {date}
                </Text>
            </Flex>
        </Flex>
    );
}

const activitySwitch = (activity: activityType, timestamp: number, userName: string): DataToSet["logs"][0] => {
    const date = timestamp ? dayjs.unix(timestamp / 1000).format("DD MMM YYYY, HH:mm") : "No Timestamp";
    const toPush: DataToSet["logs"][0] = {
        color: "primary",
        date,
        logo: FiActivity,
        title: `${userName} ${activity}`
    };
    if (activity === "add-department") {
        toPush.color = "green";
        toPush.logo = FiPlusSquare;
        toPush.title = `${userName} added department`;
    } else if (activity === "add-user") {
        toPush.color = "green";
        toPush.logo = FiUserPlus;
        toPush.title = `${userName} added user`;
    } else if (activity === "update-user") {
        toPush.color = "orange";
        toPush.logo = FiEdit3;
        toPush.title = `${userName} updated user`;
    } else if (activity === "delete-user") {
        toPush.color = "red";
        toPush.logo = FiUserMinus;
        toPush.title = `${userName} deleted user`;
    } else if (activity === "delete-department") {
        toPush.color = "red";
        toPush.logo = FiUserMinus;
        toPush.title = `${userName} deleted department`;
    } else if (activity === "update-department") {
        toPush.color = "orange";
        toPush.logo = FiEdit3;
        toPush.title = `${userName} updated department`;
    } else if (activity === "add-material") {
        toPush.color = "green";
        toPush.logo = FiPlusSquare;
        toPush.title = `${userName} added material`;
    } else if (activity === "update-material") {
        toPush.color = "orange";
        toPush.logo = FiEdit3;
        toPush.title = `${userName} updated material`;
    } else if (activity === "delete-material") {
        toPush.color = "red";
        toPush.logo = FiUserMinus;
        toPush.title = `${userName} deleted material`;
    } else if (activity === "add-mold") {
        toPush.color = "green";
        toPush.logo = FiPlusSquare;
        toPush.title = `${userName} added mold`;
    } else if (activity === "delete-mold") {
        toPush.color = "red";
        toPush.logo = FiUserMinus;
        toPush.title = `${userName} deleted mold`;
    } else if (activity === "install-mold") {
        toPush.color = "green";
        toPush.logo = FiPlusSquare;
        toPush.title = `${userName} installed mold`;
    } else if (activity === "update-equipment") {
        toPush.color = "orange";
        toPush.logo = FiEdit3;
        toPush.title = `${userName} updated equipment`;
    } else if (activity === "update-info") {
        toPush.color = "orange";
        toPush.logo = FiEdit3;
        toPush.title = `${userName} updated info`;
    } else if (activity === "update-machine") {
        toPush.color = "orange";
        toPush.logo = FiEdit3;
        toPush.title = `${userName} updated machine`;
    } else {
        toPush.color = "primary";
        toPush.logo = FiActivity;
        toPush.title = `${userName} ${activity}`;
    }
    return toPush;
}

export default ActivityModal