import { G2, Pie, PieConfig } from "@ant-design/plots";
import { Box, SimpleGrid, Text, useColorMode } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { DataToSet } from "..";
import secFormatter from "../../../../Components/Functions/formatters/secFormatter";
import Table from "../../../../Components/micro/Table";
import { downtimeCategoriesType } from "./categories";
import Categorize from "./Categorize";

const headings = [
    "Started",
    "Reason",
    "Duration",
    "Action"
]

const Downtime = ({
    data
}: {
    data: DataToSet["timing"]["downtime"]["logs"]
}) => {
    const G = G2.getEngine('canvas');
    const { colorMode } = useColorMode();
    const [myData, setMyData] = useState(data);

    useEffect(() => {
        setMyData(data);
    }, [data]);

    const updateLog = (log: {
        start: number;
        end: number;
        reason: {
            category: downtimeCategoriesType;
            note: string;
            scheduled: boolean;
        };
        id: string;
    }) => {
        if (myData === "NOT_FOUND") return;
        const index = myData.findIndex(d => d.id === log.id);
        if (index === -1) return;
        const newData = [...myData];
        newData[index] = log;
        setMyData(newData);
    }

    const [rows, notFound, pieData]: [string[][], boolean, {
        name: string;
        value: number;
    }[]] = useMemo(() => {
        const rows: any[][] = [];
        const pieData: {
            name: string;
            value: number;
        }[] = [];
        let notFound = false;
        const pieDistribution: {
            [key: string]: number
        } = {};
        if (myData === "NOT_FOUND") {
            notFound = true;
            pieDistribution["UnCategorized"] = 0;
        } else for (let i = 0; i < myData.length; i++) {
            const log = myData[i];
            const duration = Math.max(log.end - log.start, 0);
            const reason = log.reason || {
                category: "UnCategorized",
                note: "None",
                scheduled: false
            };
            if (!pieDistribution[reason.category]) pieDistribution[reason.category] = 0;
            pieDistribution[reason.category] += duration;
            rows.push([
                {
                    element: Time,
                    props: {
                        unix: log.start
                    }
                },
                {
                    element: Category,
                    props: {
                        reason
                    }
                },
                {
                    element: Duration,
                    props: {
                        duration
                    }
                },
                {
                    element: Categorize,
                    props: {
                        downtime: log,
                        updateLog
                    }
                }
            ])
        }
        for (const key in pieDistribution) {
            pieData.push({
                name: key,
                value: pieDistribution[key]
            })
        }
        return [rows, notFound, pieData];
        // eslint-disable-next-line
    }, [myData]);

    const cfg: PieConfig = {
        appendPadding: 10,
        data: pieData,
        angleField: 'value',
        colorField: 'name',
        animation: false,
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 40,
            formatter: (data: any, mappingData: any) => {
                const group = new G.Group({});
                group.addShape({
                    type: 'circle',
                    attrs: {
                        x: 0,
                        y: 0,
                        width: 40,
                        height: 50,
                        r: 5,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 10,
                        y: 8,
                        text: `${data.name}`,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 0,
                        y: 25,
                        text: `${secFormatter(data.value)}个 ${(data.percent * 100).toFixed(0)}%`,
                        fill: colorMode === "dark" ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                        fontWeight: 700,
                    },
                });
                return group;
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };


    return <SimpleGrid
        alignItems={"center"}
        columns={{
            base: 1,
            lg: 2
        }} gap={5}>
        <Pie
            height={400}
            legend={false}
            tooltip={{
                formatter: (datum: any) => {
                    return {
                        name: datum.name,
                        value: secFormatter(datum.value)
                    }
                }
            }}
            {...cfg} />
        <Box p={0} maxH="400px" overflow={"auto"}>
            <Table
                headings={headings}
                notFound={notFound}
                rows={rows}
            />
        </Box>
    </SimpleGrid>
}

const Category = ({ reason }: {
    reason: {
        category: string;
        note: string;
        scheduled: boolean;
    }
}) => {
    const { colorMode } = useColorMode();
    const { subText } = useMemo(() => ({
        subText: `${colorMode}.subText`
    }), [colorMode]);

    return <Text fontSize="sm" fontWeight={400} color={subText}>
        <Text fontWeight={600} fontSize="md" color={reason.scheduled ? "primary" : "red.400"} textTransform="uppercase">{reason.category}</Text>
        {reason.note}
    </Text>
}

const Duration = ({ duration }: { duration: number }) => {
    return <Text py={2} fontSize="md" fontWeight={500}>{secFormatter(duration)}</Text>
};

const Time = ({ unix }: { unix: number }) => {
    return <Text fontSize="xs" fontWeight={400}>
        <Text fontWeight={600} fontSize="sm">{dayjs.unix(unix).format("Do MMM, YY")}</Text>
        {dayjs.unix(unix).format("hh:mm A")}
    </Text>
}

export default Downtime
export { Time, Duration };