import {
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    useColorMode,
    TableProps,
    Skeleton,
    Center,
    Heading,
    Box
} from '@chakra-ui/react'
import { useMemo } from 'react';
import { FiDatabase } from 'react-icons/fi';

interface Props extends TableProps {
    notFound?: boolean,
    headings: string[],
    rows?: (string | number | object)[][],
    height?: string | number,
    fixHeader?: boolean,
    alignLastToLeft?: boolean,
}

const _Table = ({
    headings,
    rows,
    notFound = false,
    height = "auto",
    fixHeader = false,
    alignLastToLeft = true,
    ...props
}: Props): JSX.Element => {
    const { colorMode } = useColorMode();
    const { headBG, headColor } = useMemo(() => {
        return {
            headBG: `${colorMode}.tableHeaderBG`,
            headColor: `${colorMode}.tableHeaderColor`
        }
    }, [colorMode]);

    return <Box
        overflowX={'auto'}
        w={'100%'}>
        <Table variant='simple'
            {...props}>
            <Thead
                zIndex={fixHeader ? 1 : undefined}
                pos={fixHeader ? "absolute" : undefined}
                w={fixHeader ? "100%" : undefined}
                bgColor={headBG}>
                <Tr
                    display={fixHeader ? "flex" : undefined}
                    justifyContent={fixHeader ? "space-between" : undefined}
                    w={fixHeader ? "100%" : undefined}>
                    {headings.map((heading, i) => <Th
                        textAlign={
                            (i + 1) === headings.length && alignLastToLeft
                                ? "right"
                                : undefined
                        }
                        key={i}
                        color={headColor}>{heading}</Th>)}
                </Tr>
            </Thead>
            <Tbody
                fontSize={14}>
                {notFound ? <Tr>
                    <Td colSpan={headings.length}>
                        <Center h={"65px"} opacity={0.7}>
                            <FiDatabase size={30} />
                            <Heading
                                textTransform={"uppercase"}
                                ml={2}
                                size={"sm"}>Data not found</Heading>
                        </Center>
                    </Td>
                </Tr> : rows && rows.length !== 0 ? <>
                    {fixHeader && <Tr h={"35px"}>
                        <Td colSpan={rows.length}></Td>
                    </Tr>}
                    {rows.map((row, i) => <Tr key={i}>
                        {row.map((cell, j) => {
                            let _cell: any = cell
                            return typeof cell === "object" ?
                                _cell.element === Td ? <Box
                                    {..._cell.props}
                                    as={_cell.element}>{
                                        typeof _cell.children === "object" ? <Box
                                            {..._cell.children.props}
                                            as={_cell.children.element}>
                                            {_cell.children.children}
                                        </Box>
                                            : _cell.children
                                    }</Box>
                                    : <Td
                                        display={
                                            (j + 1) === headings.length && alignLastToLeft
                                                ? "flex"
                                                : undefined}
                                        justifyContent={
                                            (j + 1) === headings.length && alignLastToLeft
                                                ? "flex-end"
                                                : undefined}
                                        textAlign={
                                            (j + 1) === headings.length && alignLastToLeft
                                                ? "right"
                                                : undefined
                                        }
                                        key={j}>
                                        <Box
                                            {..._cell.props}
                                            as={_cell.element}>{
                                                typeof _cell.children === "object" ? <Box
                                                    {..._cell.children.props}
                                                    as={_cell.children.element}>
                                                    {_cell.children.children}
                                                </Box>
                                                    : _cell.children
                                            }</Box>
                                    </Td>
                                : <Td
                                    textAlign={
                                        (j + 1) === headings.length && alignLastToLeft
                                            ? "right"
                                            : undefined
                                    }
                                    key={j}>{cell}</Td>
                        })}
                    </Tr>)}
                </> : [...Array(5)].map((_, i) => <Tr key={i}>
                    {[...Array(headings.length)].map((_, j) => <Td key={j}><Skeleton height={6} /></Td>)}
                </Tr>)}
            </Tbody>
        </Table>
    </Box>
}

export default _Table;