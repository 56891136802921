import { Box, Center, Flex, FormControl, FormLabel, GridItem, SimpleGrid, Switch, Text, useColorMode, useToast, VStack, Icon, HStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs';
import { FiSave } from 'react-icons/fi';
import { PrimaryBtn } from '../../../Components/Buttons';
import { PrimaryInput } from '../../../Components/Inputs';
import PrimaryNumberInput from '../../../Components/Inputs/PrimaryNumberInput';
import PrimarySelect from '../../../Components/Inputs/PrimarySelect';
import SearchableSelector from '../../../Components/Inputs/SearchableSelector';
import { SmallFill } from '../../../Components/Loaders';
import BackdropLoader from '../../../Components/Loaders/BackdropLoader';
import MyCard from '../../../Components/micro/Card';
import ConfirmDialog from '../../../Components/micro/ConfirmDialog';
import MyTooltip from '../../../Components/micro/MyTooltip';
import { factoriesAtom, selectedFactoryAtom } from '../../../Components/Store/atoms';
import Get from '../../../Components/Store/hooks/Get';
import Listen from '../../../Components/Store/hooks/Listen';
import fetch from './fetch';
import save from './save';

interface MachineData {
    id: string,
    name: string,
    machineType: "standard" | "servo" | "inverter",
    idleTime: number,
    monitorDowntime: boolean,
    operatorA: string,
    operatorB: string | null,
    operatorC: string | null,
    unit: number,
}
interface MachinesData {
    [key: string]: MachineData;
}

const Machines = () => {
    const toast = useToast();
    const { colorMode } = useColorMode();
    const { text, subText, border } = useMemo(() => ({
        text: `${colorMode}.text`,
        subText: `${colorMode}.subText`,
        border: `${colorMode}.border`
    }), [colorMode]);
    const [loading, setLoading] = useState<string | null>(null);
    const [machine, setMachine] = useState<MachineData | null>(null);
    const [machines, setMachines] = useState<MachinesData | null>(null);
    const factories = Get(factoriesAtom);
    const selectedFactory = Listen(selectedFactoryAtom);

    useEffect(() => {
        if (!selectedFactory || !factories) return;
        const factory = factories?.[selectedFactory.value];
        fetch(factory).then(data => {
            setMachines(data);
            const firstMachine = Object.keys(data)[0];
            setMachine(data[firstMachine]);
        }).catch(err => {
            console.log(err);
        })
    }, [selectedFactory, factories]);

    const changeMachine = ({ label, value }: { label: string, value: string }) => {
        if (!machines) return;
        const selectedMachine = machines[value];
        setMachine(selectedMachine);
    }

    const mySave = async () => {
        if (!machine) return;
        setLoading("Updating");
        try {
            const response = await save(machine);
            if (response !== 200) throw new Error("Something went wrong");
            toast({
                title: "Success",
                description: "Machine updated successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom"
            });
            setMachines(prev => {
                if (!prev) return prev;
                return {
                    ...prev,
                    [machine.id]: machine
                }
            });
        } catch (err: any) {
            toast({
                title: "Error",
                description: err.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom"
            })
        }
        setLoading(null);
    }


    return <>
        {loading && <BackdropLoader text={loading} />}
        <Flex
            py={5}
            flexDir={{
                base: "column",
                xl: "row"
            }}
            gap={3}
            px={{
                base: 4,
                sm: 8
            }}>
            <Box w={{
                base: "100%",
                sm: "30%"
            }}>
                <Text fontSize="lg" fontWeight={500} color={text}>Machines</Text>
                <Text fontSize="sm" color={subText} mt={3}>
                    Customize machine details, change idle time, and manage operators with ease.
                </Text>
            </Box>
            <VStack w="100%" gap={5} alignItems="flex-end">
                <MyCard w="100%" px={4} py={4}>
                    {
                        machines === null || machine === null ?
                            <Center h="100px">
                                <SmallFill />
                            </Center>
                            : <VStack w="100%" gap={5}>
                                <FormControl pb={5} borderBottom="1px solid" borderColor={border}>
                                    <FormLabel color={text}>Select Machine</FormLabel>
                                    <Box
                                        w="100%">
                                        <SearchableSelector
                                            options={Object.values(machines).map(machine => ({
                                                label: machine.name,
                                                value: machine.id
                                            }))}
                                            onChange={changeMachine}
                                            value={{
                                                label: machines[machine.id].name,
                                                value: machine.id
                                            }}
                                        />
                                    </Box>
                                </FormControl>
                                <SimpleGrid
                                    gap={5}
                                    w="100%"
                                    columns={{
                                        base: 1,
                                        md: 7
                                    }}>
                                    <GridItem colSpan={{
                                        base: 1,
                                        md: 3
                                    }}>
                                        <FormControl>
                                            <FormLabel fontSize="sm" color={text}>Machine Name</FormLabel>
                                            <PrimaryInput size="sm" value={machine.name} placeholder="Machine Name" onChange={e => setMachine(prev => {
                                                if (!prev) return prev;
                                                return {
                                                    ...prev,
                                                    name: e.target.value
                                                }
                                            })} />
                                        </FormControl>
                                    </GridItem>
                                    <GridItem colSpan={{
                                        base: 1,
                                        md: 1
                                    }}>
                                        <FormControl>
                                            <FormLabel fontSize="sm" color={text}>Machine Type</FormLabel>
                                            <PrimarySelect size="sm" value={machine.machineType} onChange={e => setMachine(prev => {
                                                if (!prev) return prev;
                                                return {
                                                    ...prev,
                                                    machineType: e.target.value as "standard" | "servo" | "inverter"
                                                }
                                            })}>
                                                <option value="standard">Standard</option>
                                                <option value="inverter">Inverter</option>
                                                <option value="servo">Servo</option>
                                            </PrimarySelect>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem colSpan={{
                                        base: 1,
                                        md: 2
                                    }}>
                                        <FormControl>
                                            <FormLabel fontSize="sm" color={text}>IDLE Time</FormLabel>
                                            <PrimaryNumberInput size="sm" value={machine.idleTime} placeholder="IDLE Time" min={1} onChange={e => setMachine(prev => {
                                                if (!prev) return prev;
                                                return {
                                                    ...prev,
                                                    idleTime: parseInt(e)
                                                }
                                            })} />
                                        </FormControl>
                                    </GridItem>
                                    <FormControl>
                                        <FormLabel fontSize="sm" color={text}>Downtime</FormLabel>
                                        <HStack mt={4}>
                                            <Switch colorScheme={"orange"} isChecked={machine.monitorDowntime} onChange={e => setMachine(prev => {
                                                if (!prev) return prev;
                                                return {
                                                    ...prev,
                                                    monitorDowntime: e.target.checked
                                                }
                                            })} />
                                            <MyTooltip label="Enable Downtime Tracking: When activated, this option records instances when the machine is not in production, allowing users to input reasons for downtime.">
                                                <Box>
                                                    <Icon fontSize="xs" as={BsQuestionCircle} />
                                                </Box>
                                            </MyTooltip>
                                        </HStack>
                                    </FormControl>
                                </SimpleGrid>
                                <SimpleGrid
                                    gap={5}
                                    w="100%"
                                    columns={{
                                        base: 1,
                                        md: 3
                                    }}>
                                    <FormControl>
                                        <FormLabel fontSize="sm" color={text}>Operator A</FormLabel>
                                        <PrimaryInput size="sm" value={machine.operatorA} placeholder="Operator A" onChange={e => setMachine(prev => {
                                            if (!prev) return prev;
                                            return {
                                                ...prev,
                                                operatorA: e.target.value
                                            }
                                        })} />
                                    </FormControl>
                                    <FormControl isDisabled={machine.operatorB === null}>
                                        <FormLabel fontSize="sm" color={text}>Operator B</FormLabel>
                                        <PrimaryInput size="sm" value={machine.operatorB || ""} placeholder="Operator B" onChange={e => setMachine(prev => {
                                            if (!prev) return prev;
                                            return {
                                                ...prev,
                                                operatorB: e.target.value
                                            }
                                        })} />
                                    </FormControl>
                                    <FormControl isDisabled={machine.operatorC === null}>
                                        <FormLabel fontSize="sm" color={text}>Operator C</FormLabel>
                                        <PrimaryInput size="sm" value={machine.operatorC || ""} placeholder="Operator C" onChange={e => setMachine(prev => {
                                            if (!prev) return prev;
                                            return {
                                                ...prev,
                                                operatorC: e.target.value
                                            }
                                        })} />
                                    </FormControl>
                                </SimpleGrid>
                            </VStack>
                    }
                </MyCard>
                <ConfirmDialog onConfirm={mySave}>
                    <PrimaryBtn
                        onClick={mySave}
                        rightIcon={
                            <FiSave />
                        }>Save Changes</PrimaryBtn>
                </ConfirmDialog>
            </VStack>
        </Flex>
    </>
}


export type { MachineData, MachinesData };
export default Machines;