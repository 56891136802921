import { ModalBody, ModalFooter, ModalHeader, HStack, PinInputField, PinInput as PinInputChakra, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import Primary from '../../Buttons/Primary';
import { auth } from '../../firebase';
import BackdropLoader from '../../Loaders/BackdropLoader';
import MyModal from '../../micro/MyModal';
import emailAuth from './send';

const PinInput = ({
    isOpen,
    onClose,
    callback
}: {
    isOpen: boolean,
    onClose: () => void,
    callback: () => void
}) => {
    const toast = useToast();
    const { email } = auth.currentUser || {};
    const [retryIn, setRetryIn] = useState<number>(30);
    const [otp, setOtp] = useState<string | null>(null);
    const [loading, setLoading] = useState<string | null>(null);

    useEffect(() => {
        if (isOpen) {
            setRetryIn(30);
            setOtp(null);
        }
    }, [isOpen]);

    useEffect(() => {
        const timer = setInterval(() => {
            setRetryIn(prev => {
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const retry = async () => {
        setLoading("Sending otp to registered email");
        await emailAuth();
        setRetryIn(30);
        setLoading(null);
    };

    const verify = async () => {
        if (otp && otp.length !== 6) return;
        setLoading("Verying entered otp");
        import("./verify").then(async resp => {
            try {
                const status = await resp.default(otp as string) as string;
                if (status !== "OK") throw new Error(status);
                setLoading(null);
                onClose();
                callback();
                toast({
                    title: "Success",
                    description: "Email verified successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
            } catch (e: any) {
                toast({
                    title: "Error",
                    description: e,
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
                setLoading(null);
                setOtp(null);
            }
        });
    }

    const keypress = (e: any) => {
        if (e.key === "Enter") verify();
    }

    if (!email || !isOpen) return null;
    return <>
        {loading && <BackdropLoader text={loading} />}
        <MyModal
            isOpen={isOpen}
            onClose={onClose}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isCentered>
            <ModalHeader textAlign={"center"} fontSize="2xl">Enter OTP Received</ModalHeader>
            <ModalBody pb={6}>
                <Text textAlign={"center"}>
                    Enter the OTP sent to
                </Text>
                <Text textAlign={"center"} fontWeight="bold">
                    {email.slice(0, 4) + "*".repeat(email.length - 8) + email.slice(-4)}
                </Text>
                <HStack justifyContent={"center"} my={5} onKeyDown={keypress}>
                    <PinInputChakra colorScheme={"orange"} otp size={"lg"} onChange={setOtp} focusBorderColor={"primary"}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInputChakra>
                </HStack>
                <Text textAlign={"center"}>
                    Didn't receive OTP? <span onClick={() => retryIn > 0 ? null : retry()} style={retryIn > 0 ? {
                        color: "#718096"
                    } : {
                        color: "#ED8936",
                        cursor: "pointer"
                    }}>resend</span>
                </Text>
                <Text textAlign={"center"} fontSize="sm" opacity={0.9}>
                    {retryIn > 0 && `retry in ${retryIn} seconds`}
                </Text>
            </ModalBody>
            <ModalFooter>
                <Primary m="auto" w="100%" onClick={verify}>
                    Verify OTP
                </Primary>
            </ModalFooter>
        </MyModal>
    </>
}

export default PinInput;